// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {PlasmicTerminal} from "./plasmic/basil_site_2_0/PlasmicTerminal";
import CitySummary from "./CitySummary";
import {useNavigate} from 'react-router-dom';
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../helperComponents/AppContext";
import axios from "axios";
import {RadialChart} from "./RadialChart";
import RadialSummary from "./RadialSummary";

function Terminal_(props, ref) {
    const {state: {allCityData,}, dispatch} = useContext(AppContext);
    let navigate = useNavigate();

    function getAllCityData() {
        console.log('getting data')
        return new Promise((res, rej) => {
            axios({
                method: "GET",
                url: 'https://firebasestorage.googleapis.com/v0/b/basilcx.appspot.com/o/cities_brand_data.json?alt=media&token=5846b382-7346-4d4b-b5ec-901ab51f459d',
            }).then(response => {
                console.log('res')
                if (response.status === 200) {
                    console.log(response.data)
                    res(response.data)
                }
            }).catch(function (error) {
                console.log(error)
                if (error.response) {
                    rej('cannot get address data')
                }
            });
        })
    }

    function getAllCityNews() {
        console.log('getting new')
        return new Promise((res, rej) => {
            axios({
                method: "GET",
                url: 'https://firebasestorage.googleapis.com/v0/b/basilcx.appspot.com/o/news_cx_analytics.json?alt=media&token=5c4e79bc-5704-4ad8-9c4a-9d64773fc8d8',
            }).then(response => {
                console.log('res')
                if (response.status === 200) {
                    console.log(response.data)
                    res(response.data)
                }
            }).catch(function (error) {
                console.log(error)
                if (error.response) {
                    rej('cannot get address data')
                }
            });
        })
    }


    useEffect(() => {
        async function getData() {
            try {
                const data = await getAllCityData()
                const news = await getAllCityNews()
                dispatch({allCityData: data})
                dispatch({allCityNews: news})
            } catch (e) {
                console.log(e)
            }
        }

        getData()
    }, [])

    if (!allCityData) return null
    return <PlasmicTerminal root={{ref}} {...props}
                            citySummary={{
                                children: allCityData.map((city) => {
                                    console.log(city)
                                    return (
                                        <RadialSummary
                                            onClick={() => {
                                                dispatch({cityToShow: city})
                                                navigate('/city-page')
                                            }}
                                            cityMapImgProp={ 'https://firebasestorage.googleapis.com/v0/b/basilcx.appspot.com/o/images%2F' + city.city.toLowerCase().replace(/\s/g, '') + '.png?alt=media&token=1015069a-3585-4aad-b057-b2f3070e5051'}

                                            cityH1={city.city}
                                            numBrands={city.city_brands_total + ' brands'}
                                            numLocations={city.city_num_locs + ' locations'}
                                            // radialImg={
                                            //     <RadialChart perceptions={{
                                            //         'locsRatio': city.city_num_locs_ratio,
                                            //         "cityBrands": city.city_brands_total_ratio
                                            //     }}/>}
                                        />

                                    )
                                })
                            }}

    />;
}

const Terminal = React.forwardRef(Terminal_);

export default Terminal;
