import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Routes } from "react-router";
import Home from './components/Home';
import CityPage from "./components/CityPage";
import Terminal from "./components/Terminal";
import BrandAnalysis from "./components/BrandAnalysis";
import BasilTiles from "./components/BasilTiles";
import ContactUs from "./components/ContactUs";
import CommunityPage from "./components/CommunityPage";
import { AppContext, AppContextProvider } from "./helperComponents/AppContext";
import axios from "axios";
import LoadingScreen from './components/LoadingScreen'
import BasilChatBot from "./components/BasilChatBot";

function App() {
    // const {dispatch} = useContext(AppContext);
    const [data, setData] = useState(null)

    const [loading, setLoading] = useState(true);
    const [loadCounter, setLoadCounter] = useState(0)

    useEffect(() => {
        setTimeout(() => setLoading(false), 1500)
        setLoadCounter(1)
    }, [])

    // function getAllCityData() {
    //     console.log('getting data')
    //     return new Promise((res, rej) => {
    //         axios({
    //             method: "GET",
    //             url: 'https://firebasestorage.googleapis.com/v0/b/basilcx.appspot.com/o/cities_brand_data.json?alt=media&token=5846b382-7346-4d4b-b5ec-901ab51f459d',
    //         }).then(response => {
    //             console.log('res')
    //             if (response.status === 200) {
    //                 console.log(response.data)
    //                 res(response.data)
    //             }
    //         }).catch(function (error) {
    //             console.log(error)
    //             if (error.response) {
    //                 rej('cannot get address data')
    //             }
    //         });
    //     })
    // }


    // useEffect(() => {
    //     async function getData() {
    //         const data = await getAllCityData()
    //         console.log(data)
    //         // dispatch({allCityData: data})
    //         setData(data)
    //     }
    //
    //     getData()
    // },[])
    if (loading && loadCounter < 2) {  // && loadCounter < 1
        return <LoadingScreen />
    }
    // If page is not in loading state, display page.
    else {
        return (
            <AppContextProvider>
                <div className={"App"} style={{ display: 'block' }}>
                    <BasilChatBot />
                    <Router>
                        <Routes>
                            <Route exact path="/global-rankings" element={<Terminal />} />
                            <Route exact path="/city-page" element={<CityPage />} />
                            <Route exact path="/brand-analysis" element={<BrandAnalysis />} />
                            <Route exact path="/basil-tiles" element={<BasilTiles />} />
                            <Route exact path="/community-page" element={<CommunityPage />} />
                            <Route exact path="/contact-us" element={<ContactUs />} />
                            <Route exact path="/" element={<Home />} />
                        </Routes>
                    </Router>
                </div>
            </AppContextProvider>
        );
    }
}

export default App;
