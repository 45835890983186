// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {PlasmicBrandAnalysis} from "./plasmic/basil_site_2_0/PlasmicBrandAnalysis";
import AggBrandInsight from "./AggBrandInsight";
import {PlasmicCityPage} from "./plasmic/basil_site_2_0/PlasmicCityPage";
import BrandName from "./BrandName";
import BrandsSummaryTitle from "./BrandsSummaryTitle";
import CompanyDescriptionComp from "./CompanyDescriptionComp";
// import BrandDatapointsStats from "./BrandDatapointsStats";
import BrandPerceptionBarChart from "./BrandPerceptionBarChart";
import RadialChartCompanySentiment from "./RadialChartCompanySentiment";
import {useContext} from "react";
import {AppContext} from "../helperComponents/AppContext";
import useProtectPage from "./useProtectPage";
import {RadialChart} from "./RadialChart";
import RadialSummary from "./RadialSummary";
import {useNavigate} from "react-router";
import BarChartJs from "./BarChartJs";
// import SentimentsAnalyzed from "./SentimentsAnalyzed"

function BrandAnalysis_(props, ref) {
    const {state: {allCityData, cityToShow: city, brandToAnalyze: brand}, dispatch} = useContext(AppContext);
    const navigate = useNavigate()
    const {data} = props

    console.log('brand')
    console.log(brand)

    useProtectPage(!city && !brand)
    if (!city || !brand) return null

    return <PlasmicBrandAnalysis root={{ref}} {...props}
                                 radialSummaryCol={
                                     <RadialSummary
                                         cityMapImgProp={'https://firebasestorage.googleapis.com/v0/b/basilcx.appspot.com/o/images%2F' + city.city.toLowerCase().replace(/\s/g, '') + '.png?alt=media&token=1015069a-3585-4aad-b057-b2f3070e5051'}
                                         notWrapped={true}
                                         showButton={true}
                                         cityH1={city.city}
                                         numBrands={city.city_brands_total + ' brands'}
                                         numLocations={city.city_num_locs + ' locations'}
                                        //  radialImg={
                                        //      <RadialChart perceptions={{
                                        //          'locsRatio': city.city_num_locs_ratio,
                                        //          "cityBrands": city.city_brands_total_ratio
                                        //      }}/>
                                        //  }
                                     />
                                 }
                                 brandListCol={{
                                     children: city.brands.map((brand) => {
                                         return (
                                             <BrandName onClick={() => {
                                                 console.log('brand -  c')
                                                 console.log(brand)
                                                 dispatch({brandToAnalyze: brand})
                                             }}>{brand.brand_name}</BrandName>
                                         )
                                     })
                                 }}

                                 aggRankingList={{
                                     children: city.brands.slice(0, 10).map((brand) => {
                                         return (
                                             <AggBrandInsight
                                                 onClick={() => {
                                                     dispatch({brandToAnalyze: brand})
                                                     navigate('/brand-analysis')
                                                 }}
                                                 brandName={brand.brand_name}
                                                 brandAggScore={brand.total_score}/>
                                         )
                                     })
                                 }}
                                
                                 brandsSummaryTitle={<BrandsSummaryTitle agggScore={brand.total_score}
                                                                         cityIn={city.city}
                                                                         brandName={brand.brand_name}/>}

                                companyDescriptionComp={<CompanyDescriptionComp brandSummaries={brand.summary}/>}
                                //  brandDatapointsStats={<BrandDatapointsStats locsAnalyzed={brand.num_sentiments}
                                //                                              datapointsAnalyzed={brand.num_datapoints}/>}
                                 brandPerceptionBarChart={<BrandPerceptionBarChart
                                     sentimentBarChart={<BarChartJs perceptions={brand.topic_sent}/>}/>}

                                 radialChartCompanySentiment={
                                     <RadialChartCompanySentiment
                                         radial={<RadialChart perceptions={brand.topic_sent}/>}
                                     />
                                 }
                                 returnCityOverviewButton={{
                                     onClick: () => {
                                         navigate('/city-page')
                                     }
                                 }}
                                 returnToListOfCitiesButton={{
                                     onClick: () => {
                                         navigate('/global-rankings')
                                     }
                                 }}

    />;
}

const BrandAnalysis = React.forwardRef(BrandAnalysis_);

export default BrandAnalysis;
