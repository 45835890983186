import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import * as React from "react";
import { useState, useEffect } from "react"
import cat from "../resources/chatbot-resources/cat-chef.png"
import animalImg from "../resources/chatbot-resources/animal1.png"
import leafLogo from "../resources/chatbot-resources/leafLogo.png"
import "@fontsource/roboto"; // Defaults to weight 400.
import recipeData from '../resources/chatbot-resources/allrecipes.json'

// https://www.freepik.com/free-vector/cute-animal-doodle-elements-collection-vector_18241132.htm#query=chef%20cartoon%20animal&position=14&from_view=search&track=sph

// all available props
const theme = {
  background: '#fff',
  fontFamily: 'Roboto',
  headerBgColor: '#01bd00',
  headerFontColor: '#fff',
  headerFontSize: '15px',
  botBubbleColor: '#01bd00',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',
};



function BasilChatBot() {
  const [randRecipe, setRandRecipe] = useState(recipeData[Math.floor(Math.random() * 100)])
  console.log(randRecipe)


  function RecipeTitle() {
    return (
      <div style={{
        marginRight: 'auto',
      }}>
        <p style={{ margin: 0 }}>
          {randRecipe['title']}
        </p>
      </div>

    )
  }


  function RecipeTime() {
    return (
      <div style={{
        marginRight: 'auto',
      }}>
        {randRecipe['prep'].length > 0 ? (
          <p style={{ margin: 0 }}>
            Prep time:{"  "}
            {randRecipe['prep']}
          </p>
        ) : (<div></div>)}

        {randRecipe['total'].length > 0 ? (
          <p style={{ margin: 0 }}>
            Total time:{"  "}
            {randRecipe['total']}
          </p>
        ) : (<div></div>)}
      </div>
    )
  }

  function RecipeIngred() {
    let IngredDiv;
    if (randRecipe['ingredients-clean'].length > 0) {
      IngredDiv =
        < div style={{ marginRight: 'auto' }}>
          <p style={{ margin: 0 }}>Here are some ingredients to get you started 😊<br /><br /></p>
          {randRecipe['ingredients-clean'].slice(0, 53).map((item, index) =>
            <p style={{ margin: 0 }}>
              {item}
            </p>
          )}
        </div>
    } else {
      IngredDiv = <div></div>
    }
    return (
      <div>
        {IngredDiv}
      </div>
    )
  }


  function RecipeLink() {
    return (
      randRecipe['parent-link-href'].length > 0 ? (
        <div>
          <p style={{ margin: 0 }}>And here's a link to the full recipe: </p>
          <a style={{ textDecorationColor: "#fff7ef" }} href={randRecipe['parent-link-href']} target="_blank">
            <p style={{ margin: 0, color: "#ffffff" }}>Link here 🧑‍🍳🥗🍝🍵
            </p>
          </a>
        </div>
      ) : (<div></div>)
    )
  }


  const steps = [
    {
      id: 'hello',
      message: "How about a recipe using basil (the ingredient)? ",
      trigger: 'hello_2',
    },
    {
      id: 'hello_2',
      message: "(Yes, this is legitimately my one and only purpose as a bot)",
      trigger: 'options_want_recipe',
      delay: 1400
    },
    {
      id: 'options_want_recipe',
      options: [
        { value: 1, label: 'Yes', trigger: 'sending_recipe_msg' },
        { value: 2, label: 'No', trigger: 'change_mind_msg' },
      ],
    },
    {
      id: 'sending_recipe_msg',
      message: "Wonderful! Here's your basil recipe!",
      trigger: 'recipe_title_gen_msg',
      delay: 1500
    },
    {
      id: 'change_mind_msg',
      message: 'Aw alright. Let me know if you change your mind.',
      trigger: 'options_change_mind'
    },
    {
      id: 'options_change_mind',
      options: [
        { value: 1, label: "I've changed my mind.", trigger: 'sending_recipe_msg' },
        { value: 2, label: 'Never!', trigger: 'goodbye' },
      ],
    },
    {
      id: 'goodbye',
      message: '🥲',
      end: true,
    },
    {
      id: 'recipe_title_gen_msg',
      component: <RecipeTitle />,
      asMessage: true,
      trigger: 'recipe_time_gen_msg',
      delay: 2200
    },
    {
      id: 'recipe_time_gen_msg',
      component: <RecipeTime />,
      asMessage: true,
      trigger: 'recipe_ingred_gen_msg',
      delay: 1000
    },
    {
      id: 'recipe_ingred_gen_msg',
      component: <RecipeIngred />,
      asMessage: true,
      trigger: 'recipe_link_gen_msg',
      delay: 2000
    },
    {
      id: 'recipe_link_gen_msg',
      component: <RecipeLink />,
      asMessage: true,
      trigger: 'sassy_msg',
      delay: 3000
    },
    {
      id: 'sassy_msg',
      message: "p.s. isn't it funny how this bot is still probably more helpful/less spammy than 99% of chatbots on the internet?",
      delay: 4000,
      trigger: 'sassy_msg_2'
    },
    {
      id: 'sassy_msg_2',
      message: "Gotta love the simple things 🥰",
      delay: 2800
    }
  ];

  return (

    <div>
      <ThemeProvider theme={theme}>
        <ChatBot steps={steps}
          botAvatar={cat}
          userAvatar={animalImg}
          headerTitle={"Basil Recipes"}
          botDelay={900}
          floating={true}
          floatingIcon={leafLogo}
          floatingStyle={{ backgroundColor: "#fff" }}
          hideSubmitButton={true}
          footerStyle={{ display: "none" }}
        />
      </ThemeProvider>
    </div>
  );
}


export default BasilChatBot;
