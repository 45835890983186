// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicCallToActionBanner } from "./plasmic/basil_site_2_0/PlasmicCallToActionBanner";
import { PlasmicCityPage } from "./plasmic/basil_site_2_0/PlasmicCityPage";
import { useContext, useState, useEffect } from "react";
import { AppContext } from "../helperComponents/AppContext";

const Airtable = require('airtable');


function CallToActionBanner_(props, ref) {
    const [content, setContent] = useState('')
    const { state: { allCityData, allCityNews, cityToShow: city }, dispatch } = useContext(AppContext);
    const [subscribed, setSubscribed] = useState(false)
    const [ipData, setIpData] = useState('')

    const getIp = async () => {
        // Connect ipapi.co with fetch()
        const response = await fetch('https://ipapi.co/json/')
        const data = await response.json()
        // Set the IP address to the constant `ip`
        setIpData(data)
    }
    // Run `getIP` function above just once when the page is rendered
    useEffect(() => {
        getIp()
    }, [])

    Airtable.configure({
        endpointUrl: 'https://api.airtable.com',
        apiKey: process.env.REACT_APP_AIRTABLE_API_KEY
    });
    var base = Airtable.base('appFiG3k1MiZwfmw4');

    const url = 'https://api.airtable.com/v0/appFiG3k1MiZwfmw4/terminal_website_form'
    console.log(city)
    async function sendDataToAirTable() {
        await base('terminal_contact').create([
            {
                "fields": {
                    'email': content,
                    'city': city.city,
                    'time': new Date(Date.now()).toDateString(),
                    'ip': JSON.stringify(ipData)
                }
            },

        ], function (err, records) {
            if (err) {
                console.error(err);
                return;
            }
            setContent('')
            setSubscribed(true)
            records.forEach(function (record) {
                console.log(record.getId());
            });
        });

    }

    return <PlasmicCallToActionBanner root={{ ref }} {...props}
        textInput={{
            autoFocus: false,
            value: content,
            onChange: (e) => {
                setContent(e.target.value);
            },
        }}
        subscribeButton={{
            onClick: () => {
                sendDataToAirTable()
            },
            subscribed: subscribed
        }
        } />;
}

const CallToActionBanner = React.forwardRef(CallToActionBanner_);

export default CallToActionBanner;
