// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Bar2SvgrepoComSvgIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"currentColor"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M6 11a1 1 0 011 1v7a1 1 0 01-2 0v-7a1 1 0 011-1zm5-6v14a1 1 0 002 0V5a1 1 0 00-2 0zm6 3.5V19a1 1 0 002 0V8.5a1 1 0 00-2 0z"
        }
      ></path>
    </svg>
  );
}

export default Bar2SvgrepoComSvgIcon;
/* prettier-ignore-end */
