// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {PlasmicCityPage} from "./plasmic/basil_site_2_0/PlasmicCityPage";
import AggBrandInsight from "./AggBrandInsight";
import TweetIndividual from "./TweetIndividual";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../helperComponents/AppContext";
import useProtectPage from "./useProtectPage";
import {useNavigate} from "react-router";
import RadialSummary from "./RadialSummary";

function CityPage_(props, ref) {
    const {state: {allCityData, allCityNews, cityToShow: city}, dispatch} = useContext(AppContext);
    const navigate = useNavigate()
    const {data} = props
    useProtectPage(!city)
    if (!city) return null

    return <PlasmicCityPage root={{ref}} {...props}
                            radialSummaryCol={<RadialSummary
                                cityMapImgProp={ 'https://firebasestorage.googleapis.com/v0/b/basilcx.appspot.com/o/images%2F' + city.city.toLowerCase().replace(/\s/g, '') + '.png?alt=media&token=1015069a-3585-4aad-b057-b2f3070e5051'}
                                showButton={true}
                                notWrapped={true}
                                cityH1={city.city}
                                numBrands={city.city_brands_total + ' brands'}
                                numLocations={city.city_num_locs + ' locations'}/>
                            }

                            mapImageCity={{
                                props: {cityImage: 'https://firebasestorage.googleapis.com/v0/b/basilcx.appspot.com/o/images%2F' + city.city.toLowerCase().replace(/\s/g, '') + '.png?alt=media&token=1015069a-3585-4aad-b057-b2f3070e5051'}
                            }}

                            returnCityOverviewButton={{
                                onClick: () => {
                                    navigate('/city-page')
                                }
                            }}
                            returnToListOfCitiesButton={{
                                onClick: () => {
                                    navigate('/global-rankings')
                                }
                            }}


                            aggRankingList={{
                                children: city.brands.slice(0, 10).map((brand) => {
                                    return (
                                        <AggBrandInsight
                                            onClick={() => {
                                                dispatch({brandToAnalyze: brand})
                                                navigate('/brand-analysis')
                                            }}
                                            brandName={brand.brand_name}
                                            brandAggScore={brand.total_score}/>
                                    )
                                })
                            }}

                            fullListCol={{
                                onClick: () => {
                                    dispatch({brandToAnalyze: city.brands[0]})
                                    navigate('/brand-analysis')
                                }
                            }}


                            twitterNewsTop={{
                                children: allCityNews && allCityNews.slice(0, 2).map((article) => {
                                    return (
                                        <TweetIndividual
                                            onClick={() => {
                                                window.open(article.url, '_blank')
                                            }}
                                            accountName={article.publisher.title}
                                            tweetText={article.description}
                                        />
                                    )
                                })
                            }}

                            twitterList2={{
                                children: allCityNews && allCityNews.slice(2, 8).map((article) => {
                                    return (
                                        <TweetIndividual
                                            onClick={() => {
                                            window.open(article.url, '_blank')
                                        }}
                                                         accountName={article.publisher.title}
                                                         tweetText={article.description}

                                        />
                                    )
                                })
                            }}
    />
}

const CityPage = React.forwardRef(CityPage_);

export default CityPage;
