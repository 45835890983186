import React, {createContext, useReducer} from 'react'

export const AppContext = createContext();

const initialState = {
    allCityData: [
        {
            "city": "Dubai",
            "city_num_locs": 586,
            "city_brands_total": 35,
            "city_num_locs_ratio": 0.2063380281690141,
            "city_brands_total_ratio": 0.7777777777777778,
            "map_img": "dubai.png",
            "brands": [
                {
                    "brand_name": "Al Maya",
                    "topic_sent":
                        {
                            "price": 0.46,
                            "staff": 0.56,
                            "dining": 0.71,
                            "opening_hours": 0.6,
                            "service_efficiency": 0.52,
                            "accessibility": 0.89,
                            "cleanliness": 0.72,
                            "parking": 0.62
                        },
                    "summary": "Al Maya Supermarket is a restaurant located in UAE, serving a selection of Grocery that delivers across Reem Island, Dubai Marina and Jumeirah Beach Residence - JBR.",
                    "num_datapoints": "1.4K+",
                    "num_sentiments": "2.0K+"
                },
                {
                    "brand_name": "Apple Store",
                    "topic_sent":
                        {
                            "staff": 0.68,
                            "service_efficiency": 0.45,
                            "wait_time": 0.42,
                            "covid": 0.47,
                            "crowded": 0.49,
                            "price": 0.44,
                            "design_layout": 0.92,
                            "opening_hours": 0.18
                        },
                    "summary": "Apple Inc. is an American multinational technology company that specializes in consumer electronics, software and online services.",
                    "num_datapoints": "956.0+",
                    "num_sentiments": "1.9K+"
                },
                {
                    "brand_name": "Burger King",
                    "topic_sent":
                        {
                            "dining": 0.76,
                            "staff": 0.57,
                            "service_efficiency": 0.62,
                            "price": 0.55,
                            "cleanliness": 0.73,
                            "public_transportation": 0.72,
                            "opening_hours": 0.42,
                            "crowded": 0.38
                        },
                    "summary": "Burger King is an American multinational chain of hamburger fast food restaurants. Headquartered in Miami-Dade County, Florida, the company was founded in 1953 as Insta-Burger King, a Jacksonville, Fl...",
                    "num_datapoints": "1.4K+",
                    "num_sentiments": "3.1K+"
                },
                {
                    "brand_name": "Cafe Nero",
                    "topic_sent":
                        {
                            "staff": 0.78,
                            "service_efficiency": 0.69,
                            "dining": 0.78,
                            "seating": 0.82,
                            "price": 0.42,
                            "noise": 0.83,
                            "atmosphere": 0.89,
                            "parking": 0.47
                        },
                    "summary": "Caff\u00e8 Nero is an Italian-influenced coffeehouse company headquartered in London, England. Founded in 1997 by Gerry Ford, currently the company runs more than 1000 coffee houses in eleven countries: th...",
                    "num_datapoints": "460.0+",
                    "num_sentiments": "935.0+"
                },
                {
                    "brand_name": "Caribou Coffee",
                    "topic_sent":
                        {
                            "staff": 0.79,
                            "seating": 0.77,
                            "service_efficiency": 0.8,
                            "dining": 0.73,
                            "noise": 0.75,
                            "atmosphere": 0.79,
                            "price": 0.59,
                            "wifi": 0.53
                        },
                    "summary": "Caribou Coffee Company is an American coffee company and coffeehouse chain. Caribou Coffee was founded in Edina, Minnesota, in 1992. As of May 2015, the company operates 603 locations worldwide.",
                    "num_datapoints": "681.0+",
                    "num_sentiments": "1.5K+"
                },
                {
                    "brand_name": "Carrefour",
                    "topic_sent":
                        {
                            "price": 0.63,
                            "staff": 0.58,
                            "dining": 0.79,
                            "service_efficiency": 0.63,
                            "crowded": 0.45,
                            "accessibility": 0.92,
                            "parking": 0.64,
                            "cleanliness": 0.82
                        },
                    "summary": "Carrefour is a French multinational retail corporation headquartered in Massy, France. The eighth-largest retailer in the world by revenue, it operates a chain of hypermarkets, groceries stores and co...",
                    "num_datapoints": "4.3K+",
                    "num_sentiments": "7.4K+"
                },
                {
                    "brand_name": "Chili's",
                    "topic_sent":
                        {
                            "dining": 0.74,
                            "staff": 0.71,
                            "service_efficiency": 0.54,
                            "price": 0.42,
                            "manager": 0.61,
                            "seating": 0.74,
                            "noise": 0.55,
                            "cleanliness": 0.59
                        },
                    "summary": "Chili's Grill & Bar is an American casual dining restaurant chain. The company was founded by Larry Lavine in Texas in 1975 and is currently owned and operated by Brinker International.",
                    "num_datapoints": "1.5K+",
                    "num_sentiments": "2.8K+"
                },
                {
                    "brand_name": "Choithrams",
                    "topic_sent":
                        {
                            "price": 0.39,
                            "staff": 0.65,
                            "dining": 0.81,
                            "service_efficiency": 0.66,
                            "accessibility": 0.91,
                            "parking": 0.61,
                            "cleanliness": 0.85,
                            "opening_hours": 0.6
                        },
                    "summary": "Choithrams are the face of a large and successful network of companies, T.Choithram and Sons.\n\nT. Choithram and Sons was established in 1944 in Sierra Leone, West Africa. Since then, it has developed ...",
                    "num_datapoints": "1.3K+",
                    "num_sentiments": "2.2K+"
                },
                {
                    "brand_name": "Costa Coffee",
                    "topic_sent":
                        {
                            "staff": 0.73,
                            "service_efficiency": 0.73,
                            "dining": 0.74,
                            "price": 0.35,
                            "seating": 0.83,
                            "noise": 0.81,
                            "wifi": 0.58,
                            "cleanliness": 0.51
                        },
                    "summary": "Costa Coffee is a British coffeehouse chain with headquarters in Dunstable, England. Costa Coffee was founded in London in 1971 by brothers Bruno and Sergio Costa as a wholesale operation supplying ro...",
                    "num_datapoints": "900.0+",
                    "num_sentiments": "1.8K+"
                },
                {
                    "brand_name": "Domino's",
                    "topic_sent":
                        {
                            "staff": 0.64,
                            "service_efficiency": 0.59,
                            "dining": 0.74,
                            "price": 0.43,
                            "seating": 0.4,
                            "wait_time": 0.5,
                            "parking": 0.52,
                            "cleanliness": 0.6
                        },
                    "summary": "Domino's Pizza, Inc, doing business as Domino's, is an American multinational pizza restaurant chain founded in 1960 and led by CEO Richard Allison. The corporation is Delaware domiciled and headquart...",
                    "num_datapoints": "572.0+",
                    "num_sentiments": "1.1K+"
                },
                {
                    "brand_name": "Hilton",
                    "topic_sent":
                        {
                            "staff": 0.87,
                            "dining": 0.78,
                            "cleanliness": 0.83,
                            "service_efficiency": 0.77,
                            "price": 0.64,
                            "design_layout": 0.82,
                            "public_transportation": 0.87,
                            "wait_time": 0.77
                        },
                    "summary": "Hilton Hotels & Resorts is a global brand of full-service hotels and resorts and the flagship brand of American multinational hospitality company Hilton. The original company was founded by Conrad Hil...",
                    "num_datapoints": "1.2K+",
                    "num_sentiments": "2.9K+"
                },
                {
                    "brand_name": "Kfc",
                    "topic_sent":
                        {
                            "dining": 0.7,
                            "staff": 0.45,
                            "service_efficiency": 0.51,
                            "price": 0.66,
                            "cleanliness": 0.53,
                            "crowded": 0.36,
                            "parking": 0.43,
                            "seating": 0.66
                        },
                    "summary": "KFC is an American fast food restaurant chain headquartered in Louisville, Kentucky that specializes in fried chicken. It is the world's second-largest restaurant chain after McDonald's, with 22,621 l...",
                    "num_datapoints": "3.6K+",
                    "num_sentiments": "5.7K+"
                },
                {
                    "brand_name": "Marriott",
                    "topic_sent":
                        {
                            "staff": 0.87,
                            "dining": 0.74,
                            "service_efficiency": 0.79,
                            "cleanliness": 0.84,
                            "design_layout": 0.98,
                            "noise": 0.81,
                            "price": 0.71,
                            "accessibility": 0.8
                        },
                    "summary": "Marriott Hotels & Resorts is Marriott International's brand of full-service hotels and resorts based in Bethesda, Maryland. As of June 30, 2020, there were 582 hotels and resorts with 205,053 rooms op...",
                    "num_datapoints": "1.1K+",
                    "num_sentiments": "2.0K+"
                },
                {
                    "brand_name": "Mcdonald's",
                    "topic_sent":
                        {
                            "dining": 0.78,
                            "service_efficiency": 0.72,
                            "staff": 0.75,
                            "price": 0.5,
                            "cleanliness": 0.78,
                            "crowded": 0.38,
                            "seating": 0.8,
                            "public_transportation": 0.65
                        },
                    "summary": "McDonald's is an American multinational fast food corporation, founded in 1940 as a restaurant operated by Richard and Maurice McDonald, in San Bernardino, California, United States.",
                    "num_datapoints": "5.2K+",
                    "num_sentiments": "8.6K+"
                },
                {
                    "brand_name": "Nando's",
                    "topic_sent":
                        {
                            "dining": 0.76,
                            "staff": 0.77,
                            "service_efficiency": 0.64,
                            "price": 0.41,
                            "seating": 0.72,
                            "parking": 0.67,
                            "wait_time": 0.34,
                            "manager": 0.47
                        },
                    "summary": "Nando's is a South African multinational fast food chain that specialises in flame-grilled peri-peri style chicken. Founded in Johannesburg in 1987, Nando's operates over 1,200 outlets in 30 countries...",
                    "num_datapoints": "2.3K+",
                    "num_sentiments": "5.1K+"
                },
                {
                    "brand_name": "Pizza Hut",
                    "topic_sent":
                        {
                            "staff": 0.55,
                            "service_efficiency": 0.49,
                            "dining": 0.69,
                            "price": 0.53,
                            "wait_time": 0.44,
                            "seating": 0.72,
                            "parking": 0.47,
                            "cleanliness": 0.58
                        },
                    "summary": "Pizza Hut is an American multinational restaurant chain and international franchise founded in 1958 in Wichita, Kansas by Dan and Frank Carney. They serve their signature pan pizza and other dishes in...",
                    "num_datapoints": "2.0K+",
                    "num_sentiments": "4.2K+"
                },
                {
                    "brand_name": "Shake Shack",
                    "topic_sent":
                        {
                            "price": 0.19,
                            "dining": 0.64,
                            "staff": 0.8,
                            "service_efficiency": 0.74,
                            "cleanliness": 0.93,
                            "wait_time": 0.47,
                            "seating": 0.79,
                            "crowded": 0.44
                        },
                    "summary": "Shake Shack is an American fast casual restaurant chain based in New York City. It started out as a hot dog cart inside Madison Square Park in 2001, and its popularity steadily grew.",
                    "num_datapoints": "1.3K+",
                    "num_sentiments": "2.3K+"
                },
                {
                    "brand_name": "Sheraton",
                    "topic_sent":
                        {
                            "staff": 0.87,
                            "dining": 0.75,
                            "service_efficiency": 0.78,
                            "cleanliness": 0.96,
                            "price": 0.72,
                            "design_layout": 0.97,
                            "wifi": 0.43,
                            "public_transportation": 0.72
                        },
                    "summary": "Sheraton Hotels and Resorts is an international semi-luxury hotel chain owned by Marriott International.",
                    "num_datapoints": "674.0+",
                    "num_sentiments": "1.5K+"
                },
                {
                    "brand_name": "Spinneys",
                    "topic_sent":
                        {
                            "price": 0.27,
                            "dining": 0.79,
                            "staff": 0.87,
                            "parking": 0.47,
                            "cleanliness": 0.82,
                            "service_efficiency": 0.84,
                            "accessibility": 0.89,
                            "crowded": 0.5
                        },
                    "summary": "Spinneys is a multinational supermarket chain active in the United Arab Emirates, Egypt, Qatar, Lebanon, Oman and Pakistan. It began as a railway provision merchant company, and expanded to a grocery ...",
                    "num_datapoints": "1.7K+",
                    "num_sentiments": "3.8K+"
                },
                {
                    "brand_name": "Starbucks",
                    "topic_sent":
                        {
                            "staff": 0.69,
                            "service_efficiency": 0.71,
                            "seating": 0.74,
                            "price": 0.34,
                            "cleanliness": 0.44,
                            "wifi": 0.41,
                            "noise": 0.82,
                            "dining": 0.72
                        },
                    "summary": "Starbucks Corporation is an American multinational chain of coffeehouses and roastery reserves headquartered in Seattle, Washington. It is the world's largest coffeehouse chain. As of November 2021, t...",
                    "num_datapoints": "2.0K+",
                    "num_sentiments": "4.2K+"
                },
                {
                    "brand_name": "The Cheesecake Factory",
                    "topic_sent":
                        {
                            "dining": 0.82,
                            "staff": 0.77,
                            "service_efficiency": 0.74,
                            "price": 0.26,
                            "wait_time": 0.4,
                            "crowded": 0.39,
                            "atmosphere": 0.83,
                            "seating": 0.59
                        },
                    "summary": "The Cheesecake Factory Incorporated is an American restaurant company and distributor of cheesecakes based in the United States. The company operates 220 full-service restaurants: 206 under The Cheese...",
                    "num_datapoints": "2.2K+",
                    "num_sentiments": "4.9K+"
                },
                {
                    "brand_name": "Tim Hortons",
                    "topic_sent":
                        {
                            "staff": 0.62,
                            "dining": 0.71,
                            "service_efficiency": 0.63,
                            "price": 0.67,
                            "seating": 0.9,
                            "opening_hours": 0.44,
                            "crowded": 0.58,
                            "atmosphere": 0.84
                        },
                    "summary": "Tim Hortons Inc. is a Canadian multinational fast food restaurant chain. Based in Toronto, Tim Hortons serves coffee, doughnuts, and other fast food items. It is Canada's largest quick-service restaur...",
                    "num_datapoints": "1.1K+",
                    "num_sentiments": "2.1K+"
                },
                {
                    "brand_name": "Wendy's",
                    "topic_sent":
                        {
                            "dining": 0.7,
                            "staff": 0.66,
                            "service_efficiency": 0.57,
                            "price": 0.58,
                            "cleanliness": 0.65,
                            "seating": 0.6,
                            "public_transportation": 0.38,
                            "parking": 0.38
                        },
                    "summary": "Wendy's is an American international fast food restaurant chain founded by Dave Thomas on November 15, 1969, in Columbus, Ohio. The company moved its headquarters to Dublin, Ohio, on January 29, 2006.",
                    "num_datapoints": "575.0+",
                    "num_sentiments": "1.1K+"
                }]
        },
        {
            "city": "London",
            "city_num_locs": 1665,
            "city_brands_total": 33,
            "city_num_locs_ratio": 0.5862676056338029,
            "city_brands_total_ratio": 0.7333333333333333,
            "map_img": "london.png",
            "brands": [
                {
                    "brand_name": "Apple Store",
                    "topic_sent":
                        {
                            "staff": 0.61,
                            "service_efficiency": 0.48,
                            "wait_time": 0.32,
                            "crowded": 0.37,
                            "price": 0.21,
                            "wifi": 0.45,
                            "opening_hours": 0.24,
                            "design_layout": 0.82
                        },
                    "summary": "Apple Inc. is an American multinational technology company that specializes in consumer electronics, software and online services.",
                    "num_datapoints": "2.3K+",
                    "num_sentiments": "5.0K+"
                },
                {
                    "brand_name": "Asda",
                    "topic_sent":
                        {
                            "staff": 0.45,
                            "price": 0.73,
                            "service_efficiency": 0.41,
                            "dining": 0.7,
                            "parking": 0.67,
                            "cleanliness": 0.52,
                            "opening_hours": 0.41,
                            "covid": 0.32
                        },
                    "summary": "Asda Stores Ltd. is a British supermarket chain. It is headquartered in Leeds, England. The company was founded in 1949 when the Asquith family merged their retail business with the Associated Dairies...",
                    "num_datapoints": "3.4K+",
                    "num_sentiments": "6.5K+"
                },
                {
                    "brand_name": "Burger King",
                    "topic_sent":
                        {
                            "dining": 0.51,
                            "staff": 0.35,
                            "service_efficiency": 0.3,
                            "cleanliness": 0.29,
                            "price": 0.27,
                            "bathroom": 0.1,
                            "wait_time": 0.32,
                            "opening_hours": 0.22
                        },
                    "summary": "Burger King is an American multinational chain of hamburger fast food restaurants. Headquartered in Miami-Dade County, Florida, the company was founded in 1953 as Insta-Burger King, a Jacksonville, Fl...",
                    "num_datapoints": "4.6K+",
                    "num_sentiments": "11.0K+"
                },
                {
                    "brand_name": "Cafe Nero",
                    "topic_sent":
                        {
                            "staff": 0.75,
                            "service_efficiency": 0.7,
                            "cleanliness": 0.49,
                            "seating": 0.68,
                            "dining": 0.72,
                            "bathroom": 0.15,
                            "atmosphere": 0.86,
                            "price": 0.43
                        },
                    "summary": "Caff\u00e8 Nero is an Italian-influenced coffeehouse company headquartered in London, England. Founded in 1997 by Gerry Ford, currently the company runs more than 1000 coffee houses in eleven countries: th...",
                    "num_datapoints": "5.7K+",
                    "num_sentiments": "9.9K+"
                },
                {
                    "brand_name": "Costa Coffee",
                    "topic_sent":
                        {
                            "staff": 0.72,
                            "service_efficiency": 0.64,
                            "cleanliness": 0.52,
                            "dining": 0.68,
                            "seating": 0.63,
                            "price": 0.33,
                            "bathroom": 0.15,
                            "atmosphere": 0.8
                        },
                    "summary": "Costa Coffee is a British coffeehouse chain with headquarters in Dunstable, England. Costa Coffee was founded in London in 1971 by brothers Bruno and Sergio Costa as a wholesale operation supplying ro...",
                    "num_datapoints": "9.3K+",
                    "num_sentiments": "23.2K+"
                },
                {
                    "brand_name": "Currys",
                    "topic_sent":
                        {
                            "staff": 0.48,
                            "service_efficiency": 0.41,
                            "price": 0.46,
                            "wifi": 0.46,
                            "wait_time": 0.37,
                            "manager": 0.37,
                            "parking": 0.75,
                            "opening_hours": 0.26
                        },
                    "summary": "Currys is a British electrical retailer operating in the United Kingdom and Republic of Ireland, owned by Currys plc and selling home electronics and home appliances.",
                    "num_datapoints": "4.8K+",
                    "num_sentiments": "8.0K+"
                },
                {
                    "brand_name": "Domino's",
                    "topic_sent":
                        {
                            "service_efficiency": 0.47,
                            "staff": 0.55,
                            "dining": 0.57,
                            "wait_time": 0.4,
                            "opening_hours": 0.37,
                            "price": 0.42,
                            "manager": 0.22,
                            "repeat_visit": 0.31
                        },
                    "summary": "Domino's Pizza, Inc, doing business as Domino's, is an American multinational pizza restaurant chain founded in 1960 and led by CEO Richard Allison. The corporation is Delaware domiciled and headquart...",
                    "num_datapoints": "3.2K+",
                    "num_sentiments": "5.0K+"
                },
                {
                    "brand_name": "Five Guys",
                    "topic_sent":
                        {
                            "dining": 0.68,
                            "price": 0.24,
                            "staff": 0.81,
                            "service_efficiency": 0.76,
                            "cleanliness": 0.68,
                            "wait_time": 0.43,
                            "seating": 0.48,
                            "noise": 0.22
                        },
                    "summary": "Five Guys Enterprises LLC is an American fast casual restaurant chain focused on hamburgers, hot dogs, and French fries, and headquartered in Lorton, Virginia, part of Fairfax County.",
                    "num_datapoints": "5.3K+",
                    "num_sentiments": "10.4K+"
                },
                {
                    "brand_name": "H&m",
                    "topic_sent":
                        {
                            "staff": 0.56,
                            "price": 0.83,
                            "service_efficiency": 0.53,
                            "cleanliness": 0.88,
                            "design_layout": 0.76,
                            "crowded": 0.44,
                            "opening_hours": 0.25,
                            "wait_time": 0.52
                        },
                    "summary": "Hennes & Mauritz AB is a Swedish multinational clothing company headquartered in Stockholm. It is known for its fast-fashion clothing for men, women, teenagers, and children.",
                    "num_datapoints": "1.2K+",
                    "num_sentiments": "2.1K+"
                },
                {
                    "brand_name": "Illy",
                    "topic_sent":
                        {
                            "staff": 0.8,
                            "service_efficiency": 0.52,
                            "dining": 0.78,
                            "price": 0.42,
                            "atmosphere": 0.85,
                            "wait_time": 0.41,
                            "design_layout": 0.83,
                            "opening_hours": 0.56
                        },
                    "summary": "Illycaff\u00e8 S.p.A. is an Italian coffee company specializing in espresso, headquartered in Trieste. Illy markets its coffee globally in silver and red pressurized, oxygen-free cans; operates a network ....",
                    "num_datapoints": "451.0+",
                    "num_sentiments": "767.0+"
                },
                {
                    "brand_name": "Kfc",
                    "topic_sent":
                        {
                            "dining": 0.49,
                            "staff": 0.34,
                            "service_efficiency": 0.31,
                            "cleanliness": 0.28,
                            "wait_time": 0.35,
                            "bathroom": 0.09,
                            "price": 0.43,
                            "opening_hours": 0.27
                        },
                    "summary": "KFC is an American fast food restaurant chain headquartered in Louisville, Kentucky that specializes in fried chicken. It is the world's second-largest restaurant chain after McDonald's, with 22,621 l...",
                    "num_datapoints": "10.7K+",
                    "num_sentiments": "25.4K+"
                },
                {
                    "brand_name": "Mcdonald's",
                    "topic_sent":
                        {
                            "dining": 0.52,
                            "staff": 0.47,
                            "service_efficiency": 0.41,
                            "cleanliness": 0.52,
                            "wait_time": 0.37,
                            "bathroom": 0.12,
                            "seating": 0.38,
                            "crowded": 0.26
                        },
                    "summary": "McDonald's is an American multinational fast food corporation, founded in 1940 as a restaurant operated by Richard and Maurice McDonald, in San Bernardino, California, United States.",
                    "num_datapoints": "25.0K+",
                    "num_sentiments": "51.7K+"
                },
                {
                    "brand_name": "Nando's",
                    "topic_sent":
                        {
                            "dining": 0.69,
                            "staff": 0.73,
                            "service_efficiency": 0.57,
                            "price": 0.51,
                            "wait_time": 0.35,
                            "cleanliness": 0.57,
                            "atmosphere": 0.79,
                            "seating": 0.56
                        },
                    "summary": "Nando's is a South African multinational fast food chain that specialises in flame-grilled peri-peri style chicken. Founded in Johannesburg in 1987, Nando's operates over 1,200 outlets in 30 countries...",
                    "num_datapoints": "15.8K+",
                    "num_sentiments": "31.1K+"
                },
                {
                    "brand_name": "Pizza Hut",
                    "topic_sent":
                        {
                            "staff": 0.55,
                            "dining": 0.59,
                            "service_efficiency": 0.41,
                            "wait_time": 0.39,
                            "price": 0.48,
                            "opening_hours": 0.31,
                            "manager": 0.29,
                            "cleanliness": 0.39
                        },
                    "summary": "Pizza Hut is an American multinational restaurant chain and international franchise founded in 1958 in Wichita, Kansas by Dan and Frank Carney. They serve their signature pan pizza and other dishes in...",
                    "num_datapoints": "4.9K+",
                    "num_sentiments": "10.0K+"
                },
                {
                    "brand_name": "Pret A Manger",
                    "topic_sent":
                        {
                            "dining": 0.78,
                            "staff": 0.73,
                            "service_efficiency": 0.71,
                            "price": 0.59,
                            "seating": 0.59,
                            "cleanliness": 0.62,
                            "bathroom": 0.17,
                            "public_transportation": 0.57
                        },
                    "summary": "Pret a Manger is an international sandwich shop franchise chain based in the United Kingdom, popularly referred to as Pret. Founded in 1983, Pret currently has over 460 shops in the UK, with 324 locat...",
                    "num_datapoints": "11.0K+",
                    "num_sentiments": "26.4K+"
                },
                {
                    "brand_name": "Sainsbury's",
                    "topic_sent":
                        {
                            "staff": 0.7,
                            "price": 0.52,
                            "dining": 0.74,
                            "parking": 0.71,
                            "service_efficiency": 0.64,
                            "cleanliness": 0.77,
                            "accessibility": 0.88,
                            "opening_hours": 0.54
                        },
                    "summary": "J Sainsbury plc, trading as Sainsbury's, is the second largest chain of supermarkets in the United Kingdom, with a 16.0% share of the supermarket sector. Founded in 1869 by John James Sainsbury with a...",
                    "num_datapoints": "12.4K+",
                    "num_sentiments": "27.4K+"
                },
                {
                    "brand_name": "Shake Shack",
                    "topic_sent":
                        {
                            "dining": 0.55,
                            "price": 0.2,
                            "staff": 0.57,
                            "service_efficiency": 0.42,
                            "wait_time": 0.34,
                            "cleanliness": 0.46,
                            "seating": 0.55,
                            "crowded": 0.25
                        },
                    "summary": "Shake Shack is an American fast casual restaurant chain based in New York City. It started out as a hot dog cart inside Madison Square Park in 2001, and its popularity steadily grew.",
                    "num_datapoints": "1.7K+",
                    "num_sentiments": "3.1K+"
                },
                {
                    "brand_name": "Starbucks",
                    "topic_sent":
                        {
                            "staff": 0.63,
                            "service_efficiency": 0.53,
                            "cleanliness": 0.43,
                            "seating": 0.49,
                            "bathroom": 0.16,
                            "price": 0.23,
                            "dining": 0.59,
                            "wifi": 0.52
                        },
                    "summary": "Starbucks Corporation is an American multinational chain of coffeehouses and roastery reserves headquartered in Seattle, Washington. It is the world's largest coffeehouse chain. As of November 2021, t...",
                    "num_datapoints": "8.8K+",
                    "num_sentiments": "16.6K+"
                },
                {
                    "brand_name": "Subway",
                    "topic_sent":
                        {
                            "public_transportation": 0.64,
                            "staff": 0.5,
                            "dining": 0.65,
                            "service_efficiency": 0.51,
                            "price": 0.47,
                            "cleanliness": 0.53,
                            "opening_hours": 0.28,
                            "wait_time": 0.39
                        },
                    "summary": "Subway is an American multi-national fast food restaurant franchise that primarily sells submarine sandwiches, wraps, salads and beverages. Subway was founded by 17 year old Fred DeLuca and financed b...",
                    "num_datapoints": "5.9K+",
                    "num_sentiments": "14.6K+"
                },
                {
                    "brand_name": "Taco Bell",
                    "topic_sent":
                        {
                            "dining": 0.65,
                            "staff": 0.51,
                            "service_efficiency": 0.39,
                            "price": 0.6,
                            "wait_time": 0.38,
                            "cleanliness": 0.49,
                            "seating": 0.34,
                            "opening_hours": 0.32
                        },
                    "summary": "Taco Bell is an American-based chain of fast food restaurants originating in Irvine, California in 1962, by founder Glen Bell. Taco Bell is a subsidiary of Yum! Brands, Inc.",
                    "num_datapoints": "1.4K+",
                    "num_sentiments": "3.0K+"
                },
                {
                    "brand_name": "Tesco",
                    "topic_sent":
                        {
                            "staff": 0.46,
                            "price": 0.57,
                            "dining": 0.68,
                            "service_efficiency": 0.46,
                            "opening_hours": 0.44,
                            "cleanliness": 0.46,
                            "accessibility": 0.83,
                            "parking": 0.7
                        },
                    "summary": "Tesco PLC is a British multinational groceries and general merchandise retailer headquartered in Welwyn Garden City, England. It is the third-largest retailer in the world measured by gross revenues a...",
                    "num_datapoints": "5.8K+",
                    "num_sentiments": "13.8K+"
                },
                {
                    "brand_name": "Uniqlo",
                    "topic_sent":
                        {
                            "price": 0.81,
                            "staff": 0.64,
                            "service_efficiency": 0.62,
                            "design_layout": 0.76,
                            "cleanliness": 0.79,
                            "crowded": 0.48,
                            "wait_time": 0.38,
                            "manager": 0.22
                        },
                    "summary": "Uniqlo Co., Ltd. is a Japanese casual wear designer, manufacturer and retailer. The company is a wholly owned subsidiary of Fast Retailing Co., Ltd.",
                    "num_datapoints": "1.5K+",
                    "num_sentiments": "3.0K+"
                },
                {
                    "brand_name": "Waterstones",
                    "topic_sent":
                        {
                            "staff": 0.89,
                            "price": 0.49,
                            "service_efficiency": 0.8,
                            "atmosphere": 0.86,
                            "opening_hours": 0.64,
                            "design_layout": 0.8,
                            "noise": 0.84,
                            "bathroom": 0.36
                        },
                    "summary": "Waterstones, formerly Waterstone's, is a British book retailer that operates 283 shops, mainly in the United Kingdom and also other nearby countries. As of February 2014, it employs around 3,500 staff...",
                    "num_datapoints": "1.7K+",
                    "num_sentiments": "2.6K+"
                },
                {
                    "brand_name": "Zara",
                    "topic_sent":
                        {
                            "staff": 0.45,
                            "price": 0.71,
                            "service_efficiency": 0.31,
                            "wait_time": 0.5,
                            "design_layout": 0.76,
                            "crowded": 0.41,
                            "manager": 0.17,
                            "cleanliness": 0.64
                        },
                    "summary": "Zara SA, stylized as ZARA, is a Spanish apparel retailer based in Arteixo, A Coru\u00f1a, Galicia, Spain. The company specializes in fast fashion, and products include clothing, accessories, shoes, swimwea...",
                    "num_datapoints": "1.7K+",
                    "num_sentiments": "3.3K+"
                }]
        },
        {
            "city": "Nairobi",
            "city_num_locs": 94,
            "city_brands_total": 9,
            "city_num_locs_ratio": 0.03309859154929577,
            "city_brands_total_ratio": 0.2,
            "map_img": "nairobi.png",
            "brands": [
                {
                    "brand_name": "Artcaffe",
                    "topic_sent":
                        {
                            "dining": 0.78,
                            "service_efficiency": 0.61,
                            "staff": 0.75,
                            "noise": 0.7,
                            "atmosphere": 0.81,
                            "price": 0.43,
                            "seating": 0.77,
                            "wifi": 0.63
                        },
                    "summary": "Artcaffe's journey started fifteen years ago when the first Artcaff\u00e9 restaurant opened its doors at Westgate Mall. Artcaffe sets out to bring to life a series of high-quality, classic and inspiring re...",
                    "num_datapoints": "1.0K+",
                    "num_sentiments": "2.1K+"
                },
                {
                    "brand_name": "Java House",
                    "topic_sent":
                        {
                            "dining": 0.78,
                            "service_efficiency": 0.66,
                            "staff": 0.67,
                            "parking": 0.62,
                            "noise": 0.79,
                            "crowded": 0.42,
                            "wifi": 0.68,
                            "seating": 0.72
                        },
                    "summary": "Java House opened its first store in 1999 at Adam\u2019s Arcade in Nairobi. With the aim of introducing gourmet coffee drinking culture in Kenya, the first outlet was a coffee shop and later the brand evol...",
                    "num_datapoints": "4.1K+",
                    "num_sentiments": "9.4K+"
                },
                {
                    "brand_name": "Kfc",
                    "topic_sent":
                        {
                            "dining": 0.82,
                            "service_efficiency": 0.69,
                            "staff": 0.78,
                            "seating": 0.6,
                            "parking": 0.66,
                            "cleanliness": 0.71,
                            "crowded": 0.48,
                            "accessibility": 0.87
                        },
                    "summary": "KFC is an American fast food restaurant chain headquartered in Louisville, Kentucky that specializes in fried chicken. It is the world's second-largest restaurant chain after McDonald's, with 22,621 l...",
                    "num_datapoints": "1.6K+",
                    "num_sentiments": "3.4K+"
                },
                {
                    "brand_name": "Naivas",
                    "topic_sent":
                        {
                            "parking": 0.62,
                            "service_efficiency": 0.79,
                            "price": 0.85,
                            "staff": 0.8,
                            "accessibility": 0.91,
                            "dining": 0.83,
                            "crowded": 0.4,
                            "design_layout": 0.93
                        },
                    "summary": "Naivas Supermarket, often referred to simply as \"Naivas\", is the largest supermarket chain in Kenya, with 74 outlets as of November 2021.",
                    "num_datapoints": "2.8K+",
                    "num_sentiments": "4.6K+"
                }]
        },
        {
            "city": "New York City",
            "city_num_locs": 1952,
            "city_brands_total": 45,
            "city_num_locs_ratio": 0.6873239436619718,
            "city_brands_total_ratio": 1.0,
            "map_img": "newYorkCity.png",
            "brands": [
                {
                    "brand_name": "Acme Markets",
                    "topic_sent":
                        {
                            "price": 0.42,
                            "staff": 0.68,
                            "cleanliness": 0.86,
                            "dining": 0.68,
                            "service_efficiency": 0.58,
                            "parking": 0.7,
                            "opening_hours": 0.48,
                            "crowded": 0.55
                        },
                    "summary": "Acme Markets Inc. is a supermarket chain operating 162 stores throughout Connecticut, Delaware, Maryland, New Jersey, New York, and Pennsylvania and, as of 1999, is a subsidiary of Albertsons, and par...",
                    "num_datapoints": "1.4K+",
                    "num_sentiments": "2.9K+"
                },
                {
                    "brand_name": "Apple Store",
                    "topic_sent":
                        {
                            "staff": 0.7,
                            "service_efficiency": 0.55,
                            "wait_time": 0.32,
                            "crowded": 0.46,
                            "opening_hours": 0.32,
                            "design_layout": 0.89,
                            "manager": 0.37,
                            "wifi": 0.46
                        },
                    "summary": "Apple Inc. is an American multinational technology company that specializes in consumer electronics, software and online services.",
                    "num_datapoints": "4.0K+",
                    "num_sentiments": "8.2K+"
                },
                {
                    "brand_name": "Bed Bath & Beyond",
                    "topic_sent":
                        {
                            "staff": 0.73,
                            "price": 0.58,
                            "service_efficiency": 0.66,
                            "cleanliness": 0.9,
                            "bathroom": 0.6,
                            "crowded": 0.43,
                            "parking": 0.81,
                            "design_layout": 0.61
                        },
                    "summary": "Bed Bath & Beyond Inc. is an American chain of domestic merchandise retail stores. The chain operates many stores in the United States, Canada, Mexico, and Puerto Rico. Bed Bath & Beyond was founded i...",
                    "num_datapoints": "1.7K+",
                    "num_sentiments": "3.6K+"
                },
                {
                    "brand_name": "Best Buy",
                    "topic_sent":
                        {
                            "staff": 0.63,
                            "service_efficiency": 0.53,
                            "price": 0.6,
                            "wait_time": 0.35,
                            "wifi": 0.54,
                            "manager": 0.36,
                            "parking": 0.66,
                            "cleanliness": 0.78
                        },
                    "summary": "Best Buy is an American multinational consumer electronics retailer headquartered in Richfield, Minnesota. Originally founded by Richard M. Schulze and James Wheeler in 1966 as an audio specialty stor...",
                    "num_datapoints": "8.3K+",
                    "num_sentiments": "15.6K+"
                },
                {
                    "brand_name": "Bj's",
                    "topic_sent":
                        {
                            "staff": 0.46,
                            "price": 0.69,
                            "service_efficiency": 0.32,
                            "parking": 0.48,
                            "cleanliness": 0.77,
                            "crowded": 0.29,
                            "dining": 0.68,
                            "wait_time": 0.3
                        },
                    "summary": "BJ's Wholesale Club Holdings, Inc., commonly referred as BJ's, is an American membership-only warehouse club chain based in Westborough, Massachusetts, operating on the East Coast of the United States...",
                    "num_datapoints": "1.8K+",
                    "num_sentiments": "3.9K+"
                },
                {
                    "brand_name": "Burger King",
                    "topic_sent":
                        {
                            "dining": 0.6,
                            "staff": 0.5,
                            "service_efficiency": 0.45,
                            "cleanliness": 0.57,
                            "wait_time": 0.34,
                            "price": 0.59,
                            "manager": 0.33,
                            "opening_hours": 0.29
                        },
                    "summary": "Burger King is an American multinational chain of hamburger fast food restaurants. Headquartered in Miami-Dade County, Florida, the company was founded in 1953 as Insta-Burger King, a Jacksonville, Fl...",
                    "num_datapoints": "12.1K+",
                    "num_sentiments": "30.0K+"
                },
                {
                    "brand_name": "Chipotle",
                    "topic_sent":
                        {
                            "dining": 0.66,
                            "staff": 0.54,
                            "service_efficiency": 0.49,
                            "cleanliness": 0.56,
                            "price": 0.5,
                            "wait_time": 0.35,
                            "opening_hours": 0.23,
                            "crowded": 0.37
                        },
                    "summary": "Chipotle Mexican Grill, Inc., often known simply as Chipotle, is an American chain of fast casual restaurants in the United States, United Kingdom, Canada, Germany, and France, specializing in tacos a...",
                    "num_datapoints": "11.9K+",
                    "num_sentiments": "25.9K+"
                },
                {
                    "brand_name": "Costco",
                    "topic_sent":
                        {
                            "staff": 0.58,
                            "price": 0.74,
                            "crowded": 0.28,
                            "parking": 0.42,
                            "dining": 0.78,
                            "service_efficiency": 0.54,
                            "cleanliness": 0.83,
                            "wait_time": 0.3
                        },
                    "summary": "Costco Wholesale Corporation is an American multinational corporation which operates a chain of membership-only big-box retail stores. As of 2020, Costco was the fifth largest retailer in the world, a...",
                    "num_datapoints": "3.9K+",
                    "num_sentiments": "7.7K+"
                },
                {
                    "brand_name": "Ctown Supermarkets",
                    "topic_sent":
                        {
                            "price": 0.52,
                            "staff": 0.7,
                            "dining": 0.68,
                            "cleanliness": 0.76,
                            "service_efficiency": 0.74,
                            "manager": 0.48,
                            "accessibility": 0.91,
                            "crowded": 0.29
                        },
                    "summary": "CTown Supermarkets is a chain of independently owned and operated supermarkets operating in the northeastern United States. CTown was founded in 1975. CTown uses economies of scale so its small member...",
                    "num_datapoints": "5.6K+",
                    "num_sentiments": "12.3K+"
                },
                {
                    "brand_name": "Dollar General",
                    "topic_sent":
                        {
                            "price": 0.8,
                            "staff": 0.55,
                            "service_efficiency": 0.56,
                            "cleanliness": 0.76,
                            "dining": 0.72,
                            "manager": 0.38,
                            "accessibility": 0.86,
                            "crowded": 0.28
                        },
                    "summary": "Dollar General Corporation is an American chain of variety stores headquartered in Goodlettsville, Tennessee. As of October 2021, Dollar General operates 18,000 stores in the continental United States...",
                    "num_datapoints": "2.8K+",
                    "num_sentiments": "5.1K+"
                },
                {
                    "brand_name": "Domino's",
                    "topic_sent":
                        {
                            "service_efficiency": 0.26,
                            "dining": 0.46,
                            "wait_time": 0.37,
                            "staff": 0.38,
                            "opening_hours": 0.31,
                            "manager": 0.25,
                            "price": 0.53,
                            "repeat_visit": 0.37
                        },
                    "summary": "Domino's Pizza, Inc, doing business as Domino's, is an American multinational pizza restaurant chain founded in 1960 and led by CEO Richard Allison. The corporation is Delaware domiciled and headquart...",
                    "num_datapoints": "12.3K+",
                    "num_sentiments": "27.9K+"
                },
                {
                    "brand_name": "Dunkin'",
                    "topic_sent":
                        {
                            "staff": 0.55,
                            "service_efficiency": 0.49,
                            "dining": 0.6,
                            "cleanliness": 0.55,
                            "wait_time": 0.34,
                            "price": 0.34,
                            "opening_hours": 0.3,
                            "manager": 0.28
                        },
                    "summary": "Dunkin' Donuts LLC, also known as Dunkin' and by the initials DD, is an American multinational coffee and doughnut company, as well as a quick service restaurant. It was founded by William Rosenberg i...",
                    "num_datapoints": "8.5K+",
                    "num_sentiments": "15.3K+"
                },
                {
                    "brand_name": "Five Guys",
                    "topic_sent":
                        {
                            "dining": 0.7,
                            "staff": 0.74,
                            "price": 0.38,
                            "service_efficiency": 0.67,
                            "cleanliness": 0.57,
                            "wait_time": 0.44,
                            "crowded": 0.39,
                            "repeat_visit": 0.51
                        },
                    "summary": "Five Guys Enterprises LLC is an American fast casual restaurant chain focused on hamburgers, hot dogs, and French fries, and headquartered in Lorton, Virginia, part of Fairfax County.",
                    "num_datapoints": "4.0K+",
                    "num_sentiments": "7.1K+"
                },
                {
                    "brand_name": "Gamestop",
                    "topic_sent":
                        {
                            "staff": 0.7,
                            "service_efficiency": 0.56,
                            "price": 0.55,
                            "manager": 0.42,
                            "wait_time": 0.26,
                            "opening_hours": 0.27,
                            "cleanliness": 0.66,
                            "crowded": 0.38
                        },
                    "summary": "GameStop Corp. is an American video game, consumer electronics, and gaming merchandise retailer. The company is headquartered in Grapevine, Texas, and is the largest video game retailer worldwide.",
                    "num_datapoints": "2.8K+",
                    "num_sentiments": "5.5K+"
                },
                {
                    "brand_name": "H&m",
                    "topic_sent":
                        {
                            "price": 0.82,
                            "staff": 0.51,
                            "service_efficiency": 0.54,
                            "cleanliness": 0.84,
                            "crowded": 0.31,
                            "wait_time": 0.31,
                            "design_layout": 0.84,
                            "opening_hours": 0.39
                        },
                    "summary": "Hennes & Mauritz AB is a Swedish multinational clothing company headquartered in Stockholm. It is known for its fast-fashion clothing for men, women, teenagers, and children.",
                    "num_datapoints": "2.1K+",
                    "num_sentiments": "4.2K+"
                },
                {
                    "brand_name": "Jollibee",
                    "topic_sent":
                        {
                            "dining": 0.75,
                            "staff": 0.8,
                            "service_efficiency": 0.66,
                            "crowded": 0.36,
                            "price": 0.5,
                            "wait_time": 0.3,
                            "cleanliness": 0.86,
                            "seating": 0.24
                        },
                    "summary": "Jollibee is a Filipino multinational chain of fast food restaurants owned by Jollibee Foods Corporation. As of December 2021, JFC had a total of about 1,500 Jollibee outlets worldwide; with restaurant...",
                    "num_datapoints": "987.0+",
                    "num_sentiments": "1.8K+"
                },
                {
                    "brand_name": "Kfc",
                    "topic_sent":
                        {
                            "dining": 0.57,
                            "staff": 0.47,
                            "service_efficiency": 0.38,
                            "cleanliness": 0.38,
                            "wait_time": 0.29,
                            "price": 0.36,
                            "manager": 0.3,
                            "opening_hours": 0.28
                        },
                    "summary": "KFC is an American fast food restaurant chain headquartered in Louisville, Kentucky that specializes in fried chicken. It is the world's second-largest restaurant chain after McDonald's, with 22,621 l...",
                    "num_datapoints": "5.0K+",
                    "num_sentiments": "11.3K+"
                },
                {
                    "brand_name": "Lowe's",
                    "topic_sent":
                        {
                            "staff": 0.58,
                            "service_efficiency": 0.35,
                            "price": 0.6,
                            "cleanliness": 0.79,
                            "wait_time": 0.37,
                            "manager": 0.38,
                            "parking": 0.7,
                            "opening_hours": 0.28
                        },
                    "summary": "Lowe's Companies, Inc., often shortened to Lowe's, is an American retail company specializing in home improvement. Headquartered in Mooresville, North Carolina, the company operates a chain of retail ...",
                    "num_datapoints": "1.9K+",
                    "num_sentiments": "3.5K+"
                },
                {
                    "brand_name": "Marriott",
                    "topic_sent":
                        {
                            "staff": 0.88,
                            "cleanliness": 0.87,
                            "dining": 0.59,
                            "public_transportation": 0.82,
                            "noise": 0.48,
                            "service_efficiency": 0.46,
                            "accessibility": 0.98,
                            "design_layout": 0.72
                        },
                    "summary": "Marriott Hotels & Resorts is Marriott International's brand of full-service hotels and resorts based in Bethesda, Maryland. As of June 30, 2020, there were 582 hotels and resorts with 205,053 rooms op...",
                    "num_datapoints": "352.0+",
                    "num_sentiments": "993.0+"
                },
                {
                    "brand_name": "Mcdonald's",
                    "topic_sent":
                        {
                            "dining": 0.56,
                            "service_efficiency": 0.42,
                            "staff": 0.47,
                            "cleanliness": 0.59,
                            "wait_time": 0.35,
                            "manager": 0.27,
                            "opening_hours": 0.29,
                            "crowded": 0.23
                        },
                    "summary": "McDonald's is an American multinational fast food corporation, founded in 1940 as a restaurant operated by Richard and Maurice McDonald, in San Bernardino, California, United States.",
                    "num_datapoints": "38.3K+",
                    "num_sentiments": "85.3K+"
                },
                {
                    "brand_name": "Morton Williams Supermarket",
                    "topic_sent":
                        {
                            "price": 0.35,
                            "dining": 0.71,
                            "staff": 0.61,
                            "cleanliness": 0.8,
                            "accessibility": 0.9,
                            "service_efficiency": 0.66,
                            "opening_hours": 0.66,
                            "manager": 0.39
                        },
                    "summary": "Morton Williams Supermarkets, founded in 1952, is a family-owned and operated food retailer with sixteen stores in the New York City Metropolitan area. Morton Williams features ShopRite products as it...",
                    "num_datapoints": "1.9K+",
                    "num_sentiments": "3.9K+"
                },
                {
                    "brand_name": "Nordstrom",
                    "topic_sent":
                        {
                            "price": 0.65,
                            "staff": 0.74,
                            "service_efficiency": 0.7,
                            "cleanliness": 0.77,
                            "crowded": 0.42,
                            "wait_time": 0.36,
                            "design_layout": 0.78,
                            "accessibility": 0.86
                        },
                    "summary": "Nordstrom, Inc. is an American luxury department store chain. Founded in 1901 by John W. Nordstrom and Carl F. Wallin, it originated as a shoe store and evolved into a full-line retailer with departme...",
                    "num_datapoints": "1.3K+",
                    "num_sentiments": "2.3K+"
                },
                {
                    "brand_name": "Panda Express",
                    "topic_sent":
                        {
                            "dining": 0.69,
                            "staff": 0.59,
                            "service_efficiency": 0.51,
                            "price": 0.46,
                            "cleanliness": 0.77,
                            "wait_time": 0.25,
                            "manager": 0.32,
                            "crowded": 0.37
                        },
                    "summary": "Panda Express is an American fast food restaurant chain that serves American Chinese cuisine. With over 2,200 locations, it is the largest Asian-segment restaurant chain in the United States, where it...",
                    "num_datapoints": "2.2K+",
                    "num_sentiments": "3.9K+"
                },
                {
                    "brand_name": "Panera Bread",
                    "topic_sent":
                        {
                            "dining": 0.72,
                            "staff": 0.6,
                            "service_efficiency": 0.52,
                            "cleanliness": 0.66,
                            "price": 0.39,
                            "manager": 0.36,
                            "wait_time": 0.4,
                            "seating": 0.55
                        },
                    "summary": "Panera Bread Company is an American chain store of bakery-caf\u00e9 fast casual restaurants with over 2,000 locations, all of which are in the United States and Canada. Its headquarters are in Sunset Hills...",
                    "num_datapoints": "3.8K+",
                    "num_sentiments": "7.1K+"
                },
                {
                    "brand_name": "Pizza Hut",
                    "topic_sent":
                        {
                            "dining": 0.59,
                            "service_efficiency": 0.41,
                            "staff": 0.52,
                            "wait_time": 0.38,
                            "opening_hours": 0.32,
                            "price": 0.44,
                            "cleanliness": 0.53,
                            "manager": 0.28
                        },
                    "summary": "Pizza Hut is an American multinational restaurant chain and international franchise founded in 1958 in Wichita, Kansas by Dan and Frank Carney. They serve their signature pan pizza and other dishes in...",
                    "num_datapoints": "2.5K+",
                    "num_sentiments": "4.9K+"
                },
                {
                    "brand_name": "Pret A Manger",
                    "topic_sent":
                        {
                            "dining": 0.82,
                            "staff": 0.7,
                            "price": 0.54,
                            "service_efficiency": 0.79,
                            "cleanliness": 0.72,
                            "seating": 0.65,
                            "wifi": 0.57,
                            "manager": 0.4
                        },
                    "summary": "Pret a Manger is an international sandwich shop franchise chain based in the United Kingdom, popularly referred to as Pret. Founded in 1983, Pret currently has over 460 shops in the UK, with 324 locat...",
                    "num_datapoints": "2.5K+",
                    "num_sentiments": "5.0K+"
                },
                {
                    "brand_name": "Sephora",
                    "topic_sent":
                        {
                            "staff": 0.67,
                            "service_efficiency": 0.56,
                            "price": 0.47,
                            "crowded": 0.34,
                            "wait_time": 0.48,
                            "cleanliness": 0.77,
                            "manager": 0.45,
                            "repeat_visit": 0.67
                        },
                    "summary": "Sephora is a French multinational retailer of personal care and beauty products. Featuring nearly 3,000 brands, along with its own private label, Sephora Collection, Sephora offers beauty products inc...",
                    "num_datapoints": "1.7K+",
                    "num_sentiments": "3.6K+"
                },
                {
                    "brand_name": "Shake Shack",
                    "topic_sent":
                        {
                            "dining": 0.67,
                            "staff": 0.71,
                            "service_efficiency": 0.59,
                            "price": 0.32,
                            "wait_time": 0.35,
                            "crowded": 0.29,
                            "seating": 0.5,
                            "cleanliness": 0.68
                        },
                    "summary": "Shake Shack is an American fast casual restaurant chain based in New York City. It started out as a hot dog cart inside Madison Square Park in 2001, and its popularity steadily grew.",
                    "num_datapoints": "8.8K+",
                    "num_sentiments": "21.1K+"
                },
                {
                    "brand_name": "Sheraton",
                    "topic_sent":
                        {
                            "staff": 0.8,
                            "cleanliness": 0.71,
                            "service_efficiency": 0.67,
                            "bathroom": 0.23,
                            "dining": 0.55,
                            "public_transportation": 0.93,
                            "price": 0.41,
                            "noise": 0.34
                        },
                    "summary": "Sheraton Hotels and Resorts is an international semi-luxury hotel chain owned by Marriott International.",
                    "num_datapoints": "564.0+",
                    "num_sentiments": "1.3K+"
                },
                {
                    "brand_name": "Starbucks",
                    "topic_sent":
                        {
                            "staff": 0.61,
                            "service_efficiency": 0.49,
                            "cleanliness": 0.51,
                            "wait_time": 0.34,
                            "bathroom": 0.18,
                            "seating": 0.48,
                            "dining": 0.62,
                            "price": 0.24
                        },
                    "summary": "Starbucks Corporation is an American multinational chain of coffeehouses and roastery reserves headquartered in Seattle, Washington. It is the world's largest coffeehouse chain. As of November 2021, t...",
                    "num_datapoints": "17.3K+",
                    "num_sentiments": "37.0K+"
                },
                {
                    "brand_name": "Stop & Shop",
                    "topic_sent":
                        {
                            "price": 0.61,
                            "staff": 0.63,
                            "cleanliness": 0.79,
                            "dining": 0.79,
                            "service_efficiency": 0.58,
                            "parking": 0.64,
                            "crowded": 0.33,
                            "opening_hours": 0.45
                        },
                    "summary": "The Stop & Shop Supermarket Company, known as Stop & Shop, is a chain of supermarkets located in the northeastern United States. From its beginnings in 1892 as a small grocery store, it has grown to i...",
                    "num_datapoints": "7.8K+",
                    "num_sentiments": "15.8K+"
                },
                {
                    "brand_name": "Subway",
                    "topic_sent":
                        {
                            "public_transportation": 0.68,
                            "staff": 0.61,
                            "dining": 0.68,
                            "service_efficiency": 0.54,
                            "cleanliness": 0.72,
                            "price": 0.46,
                            "opening_hours": 0.29,
                            "manager": 0.43
                        },
                    "summary": "Subway is an American multi-national fast food restaurant franchise that primarily sells submarine sandwiches, wraps, salads and beverages. Subway was founded by 17 year old Fred DeLuca and financed b...",
                    "num_datapoints": "4.3K+",
                    "num_sentiments": "10.0K+"
                },
                {
                    "brand_name": "Taco Bell",
                    "topic_sent":
                        {
                            "dining": 0.65,
                            "staff": 0.58,
                            "service_efficiency": 0.54,
                            "cleanliness": 0.63,
                            "price": 0.57,
                            "wait_time": 0.38,
                            "manager": 0.34,
                            "opening_hours": 0.37
                        },
                    "summary": "Taco Bell is an American-based chain of fast food restaurants originating in Irvine, California in 1962, by founder Glen Bell. Taco Bell is a subsidiary of Yum! Brands, Inc.",
                    "num_datapoints": "7.4K+",
                    "num_sentiments": "17.1K+"
                },
                {
                    "brand_name": "Target",
                    "topic_sent":
                        {
                            "staff": 0.55,
                            "price": 0.69,
                            "service_efficiency": 0.53,
                            "cleanliness": 0.79,
                            "parking": 0.59,
                            "wait_time": 0.33,
                            "dining": 0.66,
                            "accessibility": 0.9
                        },
                    "summary": "Target Corporation, is an American big box department store chain headquartered in Minneapolis, Minnesota. It is the eighth largest retailer in the United States, and a component of the S&P 500 Index....",
                    "num_datapoints": "5.8K+",
                    "num_sentiments": "12.5K+"
                },
                {
                    "brand_name": "The Home Depot",
                    "topic_sent":
                        {
                            "staff": 0.54,
                            "service_efficiency": 0.41,
                            "price": 0.63,
                            "parking": 0.54,
                            "wait_time": 0.33,
                            "cleanliness": 0.69,
                            "crowded": 0.33,
                            "manager": 0.41
                        },
                    "summary": "The Home Depot, Inc., commonly known as Home Depot, is the largest home improvement retailer in the United States, supplying tools, construction products, appliances, and services. The company is head...",
                    "num_datapoints": "6.9K+",
                    "num_sentiments": "14.2K+"
                },
                {
                    "brand_name": "Tj Maxx",
                    "topic_sent":
                        {
                            "price": 0.82,
                            "staff": 0.51,
                            "service_efficiency": 0.48,
                            "cleanliness": 0.71,
                            "crowded": 0.27,
                            "wait_time": 0.32,
                            "manager": 0.33,
                            "repeat_visit": 0.54
                        },
                    "summary": "TJ Maxx is an American department store chain, selling at prices generally lower than other major similar stores. It has more than 1,000 stores in the United States, making it one of the largest cloth...",
                    "num_datapoints": "4.0K+",
                    "num_sentiments": "7.8K+"
                },
                {
                    "brand_name": "Trader Joes",
                    "topic_sent":
                        {
                            "staff": 0.81,
                            "price": 0.82,
                            "dining": 0.85,
                            "crowded": 0.32,
                            "service_efficiency": 0.69,
                            "parking": 0.38,
                            "cleanliness": 0.86,
                            "wait_time": 0.33
                        },
                    "summary": "Trader Joe's is an American chain of grocery stores headquartered in Monrovia, California. The chain has over 530 stores nationwide. The first Trader Joe's store was opened in 1967 by founder Joe Coul...",
                    "num_datapoints": "6.2K+",
                    "num_sentiments": "15.1K+"
                },
                {
                    "brand_name": "Uniqlo",
                    "topic_sent":
                        {
                            "price": 0.81,
                            "staff": 0.7,
                            "service_efficiency": 0.62,
                            "design_layout": 0.7,
                            "crowded": 0.27,
                            "cleanliness": 0.98,
                            "wait_time": 0.25,
                            "atmosphere": 0.85
                        },
                    "summary": "Uniqlo Co., Ltd. is a Japanese casual wear designer, manufacturer and retailer. The company is a wholly owned subsidiary of Fast Retailing Co., Ltd.",
                    "num_datapoints": "1.2K+",
                    "num_sentiments": "2.6K+"
                },
                {
                    "brand_name": "Walmart",
                    "topic_sent":
                        {
                            "staff": 0.4,
                            "price": 0.69,
                            "crowded": 0.27,
                            "service_efficiency": 0.35,
                            "dining": 0.7,
                            "cleanliness": 0.65,
                            "wait_time": 0.22,
                            "opening_hours": 0.28
                        },
                    "summary": "Walmart Inc. is an American multinational retail corporation that operates a chain of hypermarkets, discount department stores, and grocery stores from the United States, headquartered in Bentonville,...",
                    "num_datapoints": "1.6K+",
                    "num_sentiments": "3.6K+"
                },
                {
                    "brand_name": "Wendy's",
                    "topic_sent":
                        {
                            "dining": 0.65,
                            "service_efficiency": 0.41,
                            "staff": 0.51,
                            "cleanliness": 0.58,
                            "wait_time": 0.29,
                            "crowded": 0.23,
                            "price": 0.63,
                            "manager": 0.3
                        },
                    "summary": "Wendy's is an American international fast food restaurant chain founded by Dave Thomas on November 15, 1969, in Columbus, Ohio. The company moved its headquarters to Dublin, Ohio, on January 29, 2006.",
                    "num_datapoints": "12.9K+",
                    "num_sentiments": "25.8K+"
                },
                {
                    "brand_name": "Whole Foods",
                    "topic_sent":
                        {
                            "dining": 0.69,
                            "price": 0.37,
                            "staff": 0.56,
                            "crowded": 0.29,
                            "service_efficiency": 0.56,
                            "cleanliness": 0.81,
                            "wait_time": 0.29,
                            "design_layout": 0.64
                        },
                    "summary": "Whole Foods Market L.P. is an American multinational supermarket chain headquartered in Austin, Texas, which sells products free from hydrogenated fats and artificial colors, flavors, and preservative...",
                    "num_datapoints": "6.8K+",
                    "num_sentiments": "15.7K+"
                },
                {
                    "brand_name": "Zara",
                    "topic_sent":
                        {
                            "staff": 0.37,
                            "price": 0.66,
                            "service_efficiency": 0.33,
                            "manager": 0.24,
                            "wait_time": 0.35,
                            "opening_hours": 0.17,
                            "design_layout": 0.85,
                            "crowded": 0.35
                        },
                    "summary": "Zara SA, stylized as ZARA, is a Spanish apparel retailer based in Arteixo, A Coru\u00f1a, Galicia, Spain. The company specializes in fast fashion, and products include clothing, accessories, shoes, swimwea...",
                    "num_datapoints": "1.2K+",
                    "num_sentiments": "2.4K+"
                }]
        },
        {
            "city": "Riyadh",
            "city_num_locs": 602,
            "city_brands_total": 33,
            "city_num_locs_ratio": 0.2119718309859155,
            "city_brands_total_ratio": 0.7333333333333333,
            "map_img": "riyadh.png",
            "brands": [
                {
                    "brand_name": "Abdullah Alothaim Markets",
                    "topic_sent":
                        {
                            "price": 0.78,
                            "staff": 0.47,
                            "parking": 0.44,
                            "cleanliness": 0.75,
                            "dining": 0.85,
                            "crowded": 0.45,
                            "service_efficiency": 0.72,
                            "accessibility": 0.83
                        },
                    "summary": "Abdullah Al-Othaim Markets Company is a Saudi Arabia-based joint stock company, currently operating in Saudi Arabia and Egypt. Its main activities are food wholesaling, grocery stores, and malls.",
                    "num_datapoints": "2.1K+",
                    "num_sentiments": "3.6K+"
                },
                {
                    "brand_name": "Burger King",
                    "topic_sent":
                        {
                            "dining": 0.79,
                            "staff": 0.56,
                            "service_efficiency": 0.53,
                            "price": 0.64,
                            "cleanliness": 0.73,
                            "wait_time": 0.47,
                            "crowded": 0.59,
                            "opening_hours": 0.44
                        },
                    "summary": "Burger King is an American multinational chain of hamburger fast food restaurants. Headquartered in Miami-Dade County, Florida, the company was founded in 1953 as Insta-Burger King, a Jacksonville, Fl...",
                    "num_datapoints": "2.6K+",
                    "num_sentiments": "4.7K+"
                },
                {
                    "brand_name": "Caribou Coffee",
                    "topic_sent":
                        {
                            "price": 0.55,
                            "staff": 0.81,
                            "noise": 0.89,
                            "service_efficiency": 0.81,
                            "seating": 0.81,
                            "cleanliness": 0.84,
                            "atmosphere": 0.81,
                            "dining": 0.67
                        },
                    "summary": "Caribou Coffee Company is an American coffee company and coffeehouse chain. Caribou Coffee was founded in Edina, Minnesota, in 1992. As of May 2015, the company operates 603 locations worldwide.",
                    "num_datapoints": "997.0+",
                    "num_sentiments": "1.6K+"
                },
                {
                    "brand_name": "Carrefour",
                    "topic_sent":
                        {
                            "price": 0.7,
                            "staff": 0.42,
                            "cleanliness": 0.81,
                            "service_efficiency": 0.52,
                            "dining": 0.81,
                            "crowded": 0.45,
                            "parking": 0.65,
                            "opening_hours": 0.26
                        },
                    "summary": "Carrefour is a French multinational retail corporation headquartered in Massy, France. The eighth-largest retailer in the world by revenue, it operates a chain of hypermarkets, groceries stores and co...",
                    "num_datapoints": "1.8K+",
                    "num_sentiments": "2.7K+"
                },
                {
                    "brand_name": "Domino's",
                    "topic_sent":
                        {
                            "price": 0.69,
                            "staff": 0.56,
                            "service_efficiency": 0.64,
                            "dining": 0.71,
                            "cleanliness": 0.82,
                            "wait_time": 0.62,
                            "opening_hours": 0.38,
                            "crowded": 0.56
                        },
                    "summary": "Domino's Pizza, Inc, doing business as Domino's, is an American multinational pizza restaurant chain founded in 1960 and led by CEO Richard Allison. The corporation is Delaware domiciled and headquart...",
                    "num_datapoints": "1.6K+",
                    "num_sentiments": "3.0K+"
                },
                {
                    "brand_name": "Dunkin'",
                    "topic_sent":
                        {
                            "price": 0.74,
                            "staff": 0.51,
                            "service_efficiency": 0.57,
                            "cleanliness": 0.68,
                            "crowded": 0.47,
                            "opening_hours": 0.49,
                            "noise": 0.9,
                            "dining": 0.72
                        },
                    "summary": "Dunkin' Donuts LLC, also known as Dunkin' and by the initials DD, is an American multinational coffee and doughnut company, as well as a quick service restaurant. It was founded by William Rosenberg i...",
                    "num_datapoints": "4.7K+",
                    "num_sentiments": "7.5K+"
                },
                {
                    "brand_name": "Hilton",
                    "topic_sent":
                        {
                            "dining": 0.62,
                            "staff": 0.78,
                            "service_efficiency": 0.63,
                            "cleanliness": 0.74,
                            "price": 0.5,
                            "parking": 0.37,
                            "noise": 0.85,
                            "design_layout": 0.85
                        },
                    "summary": "Hilton Hotels & Resorts is a global brand of full-service hotels and resorts and the flagship brand of American multinational hospitality company Hilton. The original company was founded by Conrad Hil...",
                    "num_datapoints": "739.0+",
                    "num_sentiments": "1.4K+"
                },
                {
                    "brand_name": "Ihg Hotel",
                    "topic_sent":
                        {
                            "staff": 0.85,
                            "dining": 0.73,
                            "service_efficiency": 0.8,
                            "cleanliness": 0.86,
                            "noise": 0.3,
                            "wifi": 0.59,
                            "parking": 0.5,
                            "price": 0.48
                        },
                    "summary": "InterContinental Hotels Group plc, marketed as IHG Hotels & Resorts, is a British multinational hospitality company headquartered in Denham, Buckinghamshire, England. It is listed on the London Stock ...",
                    "num_datapoints": "1.2K+",
                    "num_sentiments": "2.5K+"
                },
                {
                    "brand_name": "Kfc",
                    "topic_sent":
                        {
                            "dining": 0.69,
                            "service_efficiency": 0.53,
                            "staff": 0.47,
                            "price": 0.49,
                            "cleanliness": 0.64,
                            "wait_time": 0.47,
                            "crowded": 0.45,
                            "manager": 0.51
                        },
                    "summary": "KFC is an American fast food restaurant chain headquartered in Louisville, Kentucky that specializes in fried chicken. It is the world's second-largest restaurant chain after McDonald's, with 22,621 l...",
                    "num_datapoints": "3.6K+",
                    "num_sentiments": "5.9K+"
                },
                {
                    "brand_name": "Mcdonald's",
                    "topic_sent":
                        {
                            "dining": 0.76,
                            "service_efficiency": 0.54,
                            "staff": 0.51,
                            "cleanliness": 0.79,
                            "price": 0.75,
                            "crowded": 0.48,
                            "wait_time": 0.43,
                            "opening_hours": 0.41
                        },
                    "summary": "McDonald's is an American multinational fast food corporation, founded in 1940 as a restaurant operated by Richard and Maurice McDonald, in San Bernardino, California, United States.",
                    "num_datapoints": "6.6K+",
                    "num_sentiments": "14.2K+"
                },
                {
                    "brand_name": "Pizza Hut",
                    "topic_sent":
                        {
                            "price": 0.56,
                            "staff": 0.53,
                            "service_efficiency": 0.6,
                            "dining": 0.66,
                            "cleanliness": 0.53,
                            "opening_hours": 0.39,
                            "wait_time": 0.36,
                            "bathroom": 0.25
                        },
                    "summary": "Pizza Hut is an American multinational restaurant chain and international franchise founded in 1958 in Wichita, Kansas by Dan and Frank Carney. They serve their signature pan pizza and other dishes in...",
                    "num_datapoints": "879.0+",
                    "num_sentiments": "1.7K+"
                },
                {
                    "brand_name": "Sheraton",
                    "topic_sent":
                        {
                            "staff": 0.83,
                            "dining": 0.66,
                            "service_efficiency": 0.75,
                            "cleanliness": 0.63,
                            "parking": 0.4,
                            "price": 0.45,
                            "design_layout": 0.83,
                            "bathroom": 0.26
                        },
                    "summary": "Sheraton Hotels and Resorts is an international semi-luxury hotel chain owned by Marriott International.",
                    "num_datapoints": "578.0+",
                    "num_sentiments": "1.3K+"
                },
                {
                    "brand_name": "Starbucks",
                    "topic_sent":
                        {
                            "staff": 0.68,
                            "price": 0.45,
                            "service_efficiency": 0.68,
                            "crowded": 0.48,
                            "cleanliness": 0.61,
                            "opening_hours": 0.4,
                            "noise": 0.81,
                            "seating": 0.69
                        },
                    "summary": "Starbucks Corporation is an American multinational chain of coffeehouses and roastery reserves headquartered in Seattle, Washington. It is the world's largest coffeehouse chain. As of November 2021, t...",
                    "num_datapoints": "5.6K+",
                    "num_sentiments": "8.6K+"
                },
                {
                    "brand_name": "Subway",
                    "topic_sent":
                        {
                            "dining": 0.86,
                            "price": 0.62,
                            "staff": 0.6,
                            "public_transportation": 0.71,
                            "cleanliness": 0.81,
                            "service_efficiency": 0.63,
                            "opening_hours": 0.41,
                            "crowded": 0.52
                        },
                    "summary": "Subway is an American multi-national fast food restaurant franchise that primarily sells submarine sandwiches, wraps, salads and beverages. Subway was founded by 17 year old Fred DeLuca and financed b...",
                    "num_datapoints": "2.1K+",
                    "num_sentiments": "3.2K+"
                },
                {
                    "brand_name": "Tim Hortons",
                    "topic_sent":
                        {
                            "price": 0.61,
                            "staff": 0.65,
                            "service_efficiency": 0.73,
                            "dining": 0.7,
                            "cleanliness": 0.74,
                            "seating": 0.68,
                            "noise": 0.91,
                            "atmosphere": 0.81
                        },
                    "summary": "Tim Hortons Inc. is a Canadian multinational fast food restaurant chain. Based in Toronto, Tim Hortons serves coffee, doughnuts, and other fast food items. It is Canada's largest quick-service restaur...",
                    "num_datapoints": "1.0K+",
                    "num_sentiments": "1.9K+"
                },
                {
                    "brand_name": "Xcite Electronics",
                    "topic_sent":
                        {
                            "price": 0.72,
                            "staff": 0.61,
                            "service_efficiency": 0.43,
                            "wifi": 0.56,
                            "wait_time": 0.2,
                            "design_layout": 0.86,
                            "opening_hours": 0.34,
                            "crowded": 0.5
                        },
                    "summary": "Xcite is an electronics store currently based in Kuwait. Their product line includes computers & tablets, mobile phones, cameras, TVs, audio & MP3, games & toys, major appliances, small housewares, wa...",
                    "num_datapoints": "1.7K+",
                    "num_sentiments": "2.9K+"
                }]
        },
        {
            "city": "Singapore",
            "city_num_locs": 951,
            "city_brands_total": 35,
            "city_num_locs_ratio": 0.33485915492957746,
            "city_brands_total_ratio": 0.7777777777777778,
            "map_img": "singapore.png",
            "brands": [
                {
                    "brand_name": "Apple Store",
                    "topic_sent":
                        {
                            "staff": 0.75,
                            "service_efficiency": 0.66,
                            "wait_time": 0.36,
                            "design_layout": 0.9,
                            "crowded": 0.33,
                            "covid": 0.38,
                            "opening_hours": 0.15,
                            "price": 0.25
                        },
                    "summary": "Apple Inc. is an American multinational technology company that specializes in consumer electronics, software and online services.",
                    "num_datapoints": "926.0+",
                    "num_sentiments": "1.6K+"
                },
                {
                    "brand_name": "Arabica",
                    "topic_sent":
                        {
                            "price": 0.15,
                            "staff": 0.4,
                            "service_efficiency": 0.4,
                            "crowded": 0.3,
                            "seating": 0.34,
                            "design_layout": 0.64,
                            "wait_time": 0.38,
                            "atmosphere": 0.73
                        },
                    "summary": "Coffea arabica, also known as the Arabian coffee, is a species of flowering plant in the coffee and madder family Rubiaceae. It is believed to be the first species of coffee to have been cultivated, a...",
                    "num_datapoints": "605.0+",
                    "num_sentiments": "1.2K+"
                },
                {
                    "brand_name": "Astons",
                    "topic_sent":
                        {
                            "dining": 0.76,
                            "price": 0.68,
                            "staff": 0.61,
                            "service_efficiency": 0.49,
                            "crowded": 0.47,
                            "wait_time": 0.31,
                            "design_layout": 0.92,
                            "noise": 0.69
                        },
                    "summary": "Aston's brings Western cuisine to the masses at affordable prices. The Aston's brand has established itself as a household name, focusing on quality and affordability.",
                    "num_datapoints": "2.1K+",
                    "num_sentiments": "4.7K+"
                },
                {
                    "brand_name": "Burger King",
                    "topic_sent":
                        {
                            "dining": 0.58,
                            "staff": 0.42,
                            "service_efficiency": 0.27,
                            "crowded": 0.41,
                            "cleanliness": 0.58,
                            "wait_time": 0.39,
                            "price": 0.45,
                            "noise": 0.88
                        },
                    "summary": "Burger King is an American multinational chain of hamburger fast food restaurants. Headquartered in Miami-Dade County, Florida, the company was founded in 1953 as Insta-Burger King, a Jacksonville, Fl...",
                    "num_datapoints": "3.0K+",
                    "num_sentiments": "6.2K+"
                },
                {
                    "brand_name": "Cold Storage",
                    "topic_sent":
                        {
                            "price": 0.29,
                            "staff": 0.59,
                            "dining": 0.77,
                            "accessibility": 0.91,
                            "crowded": 0.44,
                            "cleanliness": 0.9,
                            "service_efficiency": 0.62,
                            "parking": 0.62
                        },
                    "summary": "Cold Storage is a supermarket chain in Singapore, owned by Dairy Farm International Holdings. It operates three supermarket brands in Singapore and Malaysia: Cold Storage, Market Place, and Giant Hype...",
                    "num_datapoints": "1.8K+",
                    "num_sentiments": "3.1K+"
                },
                {
                    "brand_name": "Craftsmen Specialty Coffee",
                    "topic_sent":
                        {
                            "dining": 0.73,
                            "staff": 0.82,
                            "price": 0.21,
                            "service_efficiency": 0.61,
                            "atmosphere": 0.86,
                            "crowded": 0.29,
                            "noise": 0.68,
                            "repeat_visit": 0.76
                        },
                    "summary": "Craftsmen Specialty Coffee is a Singapore independent coffee shop serving variety of specialty coffee and mouth-watering brunches. Established since 2013, we are inspired to serve great coffees with q...",
                    "num_datapoints": "1.0K+",
                    "num_sentiments": "1.9K+"
                },
                {
                    "brand_name": "Crystal Jade",
                    "topic_sent":
                        {
                            "dining": 0.65,
                            "staff": 0.63,
                            "service_efficiency": 0.55,
                            "price": 0.31,
                            "crowded": 0.42,
                            "opening_hours": 0.34,
                            "wait_time": 0.23,
                            "noise": 0.38
                        },
                    "summary": "Crystal Jade, owned by Crystal Jade Culinary Concept Holdings, is a Singapore-based Chinese restaurant chain.",
                    "num_datapoints": "1.6K+",
                    "num_sentiments": "3.8K+"
                },
                {
                    "brand_name": "Din Tai Fung",
                    "topic_sent":
                        {
                            "dining": 0.72,
                            "service_efficiency": 0.58,
                            "staff": 0.71,
                            "crowded": 0.35,
                            "price": 0.36,
                            "wait_time": 0.3,
                            "opening_hours": 0.52,
                            "noise": 0.53
                        },
                    "summary": "Din Tai Fung is a Taiwanese restaurant chain that specializes in Chinese Huaiyang cuisine. Outside Taiwan, Din Tai Fung also has branches in Australia, China, Hong Kong, Indonesia, Japan, Macau, Malay...",
                    "num_datapoints": "2.8K+",
                    "num_sentiments": "5.2K+"
                },
                {
                    "brand_name": "Domino's",
                    "topic_sent":
                        {
                            "staff": 0.67,
                            "service_efficiency": 0.48,
                            "dining": 0.58,
                            "wait_time": 0.43,
                            "price": 0.58,
                            "cleanliness": 0.78,
                            "accessibility": 0.86,
                            "opening_hours": 0.3
                        },
                    "summary": "Domino's Pizza, Inc, doing business as Domino's, is an American multinational pizza restaurant chain founded in 1960 and led by CEO Richard Allison. The corporation is Delaware domiciled and headquart...",
                    "num_datapoints": "1.2K+",
                    "num_sentiments": "2.2K+"
                },
                {
                    "brand_name": "Fairprice",
                    "topic_sent":
                        {
                            "price": 0.54,
                            "staff": 0.6,
                            "crowded": 0.33,
                            "accessibility": 0.9,
                            "dining": 0.72,
                            "opening_hours": 0.5,
                            "design_layout": 0.83,
                            "service_efficiency": 0.58
                        },
                    "summary": "NTUC FairPrice Co-Operative is a supermarket chain based in Singapore and the largest in the country. The company is a co-operative of the National Trades Union Congress. The group has 100 supermarket...",
                    "num_datapoints": "8.0K+",
                    "num_sentiments": "16.2K+"
                },
                {
                    "brand_name": "Giant Supermarket",
                    "topic_sent":
                        {
                            "price": 0.65,
                            "staff": 0.45,
                            "dining": 0.68,
                            "accessibility": 0.95,
                            "crowded": 0.42,
                            "parking": 0.89,
                            "opening_hours": 0.6,
                            "design_layout": 0.81
                        },
                    "summary": "The Giant Company is an American supermarket chain that operates stores in Pennsylvania, Maryland, Virginia, and West Virginia. The chain operates full-scale supermarkets under the Giant and Martin's ...",
                    "num_datapoints": "1.5K+",
                    "num_sentiments": "2.5K+"
                },
                {
                    "brand_name": "Harvey Norman",
                    "topic_sent":
                        {
                            "staff": 0.74,
                            "price": 0.52,
                            "service_efficiency": 0.59,
                            "wifi": 0.63,
                            "design_layout": 0.91,
                            "crowded": 0.5,
                            "cleanliness": 0.55,
                            "accessibility": 0.86
                        },
                    "summary": "Harvey Norman is a large Australian-based, multi-national retailer of furniture, bedding, computers, communications and consumer electrical products. It mainly operates as a franchise, with the main b...",
                    "num_datapoints": "749.0+",
                    "num_sentiments": "1.6K+"
                },
                {
                    "brand_name": "Hilton",
                    "topic_sent":
                        {
                            "staff": 0.77,
                            "dining": 0.71,
                            "service_efficiency": 0.67,
                            "cleanliness": 0.85,
                            "price": 0.35,
                            "accessibility": 0.86,
                            "design_layout": 0.85,
                            "public_transportation": 0.69
                        },
                    "summary": "Hilton Hotels & Resorts is a global brand of full-service hotels and resorts and the flagship brand of American multinational hospitality company Hilton. The original company was founded by Conrad Hil...",
                    "num_datapoints": "1.2K+",
                    "num_sentiments": "2.2K+"
                },
                {
                    "brand_name": "Jollibee",
                    "topic_sent":
                        {
                            "dining": 0.61,
                            "staff": 0.35,
                            "service_efficiency": 0.26,
                            "crowded": 0.21,
                            "wait_time": 0.35,
                            "price": 0.53,
                            "opening_hours": 0.41,
                            "manager": 0.18
                        },
                    "summary": "Jollibee is a Filipino multinational chain of fast food restaurants owned by Jollibee Foods Corporation. As of December 2021, JFC had a total of about 1,500 Jollibee outlets worldwide; with restaurant...",
                    "num_datapoints": "895.0+",
                    "num_sentiments": "2.2K+"
                },
                {
                    "brand_name": "Kfc",
                    "topic_sent":
                        {
                            "dining": 0.54,
                            "staff": 0.39,
                            "service_efficiency": 0.27,
                            "crowded": 0.33,
                            "cleanliness": 0.48,
                            "wait_time": 0.31,
                            "opening_hours": 0.29,
                            "price": 0.37
                        },
                    "summary": "KFC is an American fast food restaurant chain headquartered in Louisville, Kentucky that specializes in fried chicken. It is the world's second-largest restaurant chain after McDonald's, with 22,621 l...",
                    "num_datapoints": "3.3K+",
                    "num_sentiments": "7.2K+"
                },
                {
                    "brand_name": "Marriott",
                    "topic_sent":
                        {
                            "staff": 0.83,
                            "dining": 0.67,
                            "service_efficiency": 0.71,
                            "design_layout": 0.88,
                            "cleanliness": 0.75,
                            "public_transportation": 0.92,
                            "bathroom": 0.64,
                            "accessibility": 0.91
                        },
                    "summary": "Marriott Hotels & Resorts is Marriott International's brand of full-service hotels and resorts based in Bethesda, Maryland. As of June 30, 2020, there were 582 hotels and resorts with 205,053 rooms op...",
                    "num_datapoints": "634.0+",
                    "num_sentiments": "2.0K+"
                },
                {
                    "brand_name": "Mcdonald's",
                    "topic_sent":
                        {
                            "dining": 0.67,
                            "staff": 0.65,
                            "service_efficiency": 0.53,
                            "crowded": 0.32,
                            "cleanliness": 0.62,
                            "accessibility": 0.91,
                            "opening_hours": 0.42,
                            "seating": 0.58
                        },
                    "summary": "McDonald's is an American multinational fast food corporation, founded in 1940 as a restaurant operated by Richard and Maurice McDonald, in San Bernardino, California, United States.",
                    "num_datapoints": "15.6K+",
                    "num_sentiments": "35.0K+"
                },
                {
                    "brand_name": "Nando's",
                    "topic_sent":
                        {
                            "dining": 0.68,
                            "staff": 0.58,
                            "service_efficiency": 0.39,
                            "price": 0.4,
                            "wait_time": 0.38,
                            "crowded": 0.39,
                            "covid": 0.46,
                            "opening_hours": 0.29
                        },
                    "summary": "Nando's is a South African multinational fast food chain that specialises in flame-grilled peri-peri style chicken. Founded in Johannesburg in 1987, Nando's operates over 1,200 outlets in 30 countries...",
                    "num_datapoints": "948.0+",
                    "num_sentiments": "2.0K+"
                },
                {
                    "brand_name": "Pizza Hut",
                    "topic_sent":
                        {
                            "staff": 0.5,
                            "dining": 0.53,
                            "service_efficiency": 0.32,
                            "price": 0.36,
                            "wait_time": 0.38,
                            "crowded": 0.39,
                            "manager": 0.43,
                            "noise": 0.84
                        },
                    "summary": "Pizza Hut is an American multinational restaurant chain and international franchise founded in 1958 in Wichita, Kansas by Dan and Frank Carney. They serve their signature pan pizza and other dishes in...",
                    "num_datapoints": "1.5K+",
                    "num_sentiments": "3.5K+"
                },
                {
                    "brand_name": "Shake Shack",
                    "topic_sent":
                        {
                            "dining": 0.55,
                            "price": 0.18,
                            "staff": 0.53,
                            "service_efficiency": 0.48,
                            "crowded": 0.43,
                            "wait_time": 0.35,
                            "seating": 0.65,
                            "cleanliness": 0.68
                        },
                    "summary": "Shake Shack is an American fast casual restaurant chain based in New York City. It started out as a hot dog cart inside Madison Square Park in 2001, and its popularity steadily grew.",
                    "num_datapoints": "1.3K+",
                    "num_sentiments": "2.7K+"
                },
                {
                    "brand_name": "Sheng Siong",
                    "topic_sent":
                        {
                            "price": 0.74,
                            "staff": 0.74,
                            "accessibility": 0.94,
                            "dining": 0.74,
                            "crowded": 0.36,
                            "opening_hours": 0.58,
                            "service_efficiency": 0.74,
                            "cleanliness": 0.78
                        },
                    "summary": "Sheng Siong Group Ltd. is the parent company of Sheng Siong Supermarket Pte Ltd, commonly known as Sheng Siong, the third largest chain of supermarkets in Singapore. The supermarket chain also airs it...",
                    "num_datapoints": "4.6K+",
                    "num_sentiments": "10.4K+"
                },
                {
                    "brand_name": "Sheraton",
                    "topic_sent":
                        {
                            "dining": 0.63,
                            "staff": 0.81,
                            "service_efficiency": 0.63,
                            "cleanliness": 0.85,
                            "public_transportation": 0.69,
                            "price": 0.49,
                            "accessibility": 0.92,
                            "design_layout": 0.9
                        },
                    "summary": "Sheraton Hotels and Resorts is an international semi-luxury hotel chain owned by Marriott International.",
                    "num_datapoints": "1.2K+",
                    "num_sentiments": "3.0K+"
                },
                {
                    "brand_name": "Starbucks",
                    "topic_sent":
                        {
                            "staff": 0.53,
                            "crowded": 0.34,
                            "service_efficiency": 0.47,
                            "dining": 0.56,
                            "seating": 0.49,
                            "noise": 0.64,
                            "wifi": 0.39,
                            "price": 0.22
                        },
                    "summary": "Starbucks Corporation is an American multinational chain of coffeehouses and roastery reserves headquartered in Seattle, Washington. It is the world's largest coffeehouse chain. As of November 2021, t...",
                    "num_datapoints": "3.4K+",
                    "num_sentiments": "9.4K+"
                },
                {
                    "brand_name": "Subway",
                    "topic_sent":
                        {
                            "public_transportation": 0.72,
                            "staff": 0.52,
                            "dining": 0.74,
                            "service_efficiency": 0.61,
                            "price": 0.58,
                            "crowded": 0.39,
                            "cleanliness": 0.84,
                            "noise": 0.82
                        },
                    "summary": "Subway is an American multi-national fast food restaurant franchise that primarily sells submarine sandwiches, wraps, salads and beverages. Subway was founded by 17 year old Fred DeLuca and financed b...",
                    "num_datapoints": "1.9K+",
                    "num_sentiments": "4.3K+"
                },
                {
                    "brand_name": "The Coffee Bean And Tea Leaf",
                    "topic_sent":
                        {
                            "staff": 0.5,
                            "dining": 0.61,
                            "service_efficiency": 0.46,
                            "crowded": 0.37,
                            "wifi": 0.39,
                            "noise": 0.77,
                            "price": 0.27,
                            "seating": 0.54
                        },
                    "summary": "The Coffee Bean & Tea Leaf is an American coffee shop chain founded in 1963. Since 2019, it is a trade name of Ireland-based Super Magnificent Coffee Company Ireland Limited, itself wholly owned subsi...",
                    "num_datapoints": "2.0K+",
                    "num_sentiments": "3.6K+"
                }]
        },
        {
            "city": "Tokyo",
            "city_num_locs": 2840,
            "city_brands_total": 43,
            "city_num_locs_ratio": 1.0,
            "city_brands_total_ratio": 0.9555555555555556,
            "map_img": "tokyo.png",
            "brands": [
                {
                    "brand_name": "Aeon Supermarket",
                    "topic_sent":
                        {
                            "dining": 0.67,
                            "parking": 0.63,
                            "accessibility": 0.94,
                            "price": 0.66,
                            "crowded": 0.49,
                            "public_transportation": 0.73,
                            "opening_hours": 0.6,
                            "staff": 0.55
                        },
                    "summary": "\u00c6ON is the largest retailer in Asia. \u00c6ON is a retail network comprising around 300 consolidated subsidiaries and 26 equity-method affiliated companies. These range from convenience stores \"Ministop\" a...",
                    "num_datapoints": "4.4K+",
                    "num_sentiments": "8.2K+"
                },
                {
                    "brand_name": "Apple Store",
                    "topic_sent":
                        {
                            "staff": 0.61,
                            "service_efficiency": 0.53,
                            "wait_time": 0.45,
                            "crowded": 0.52,
                            "public_transportation": 0.7,
                            "atmosphere": 0.71,
                            "accessibility": 0.95,
                            "covid": 0.61
                        },
                    "summary": "Apple Inc. is an American multinational technology company that specializes in consumer electronics, software and online services.",
                    "num_datapoints": "639.0+",
                    "num_sentiments": "1.1K+"
                },
                {
                    "brand_name": "Bic Camera & Kojima \u00d7 Bic Camera",
                    "topic_sent":
                        {
                            "price": 0.54,
                            "staff": 0.58,
                            "public_transportation": 0.8,
                            "accessibility": 0.88,
                            "service_efficiency": 0.55,
                            "crowded": 0.39,
                            "parking": 0.59,
                            "wait_time": 0.44
                        },
                    "summary": "Bic Camera is a consumer electronics retailer chain in Japan. Currently, it has 41 stores in Japan. Bic Camera has a 50% ownership of former rival store Kojima and full ownership of computer store cha...",
                    "num_datapoints": "4.4K+",
                    "num_sentiments": "8.4K+"
                },
                {
                    "brand_name": "Burger King",
                    "topic_sent":
                        {
                            "price": 0.57,
                            "public_transportation": 0.7,
                            "dining": 0.74,
                            "staff": 0.58,
                            "crowded": 0.43,
                            "service_efficiency": 0.51,
                            "cleanliness": 0.57,
                            "wait_time": 0.44
                        },
                    "summary": "Burger King is an American multinational chain of hamburger fast food restaurants. Headquartered in Miami-Dade County, Florida, the company was founded in 1953 as Insta-Burger King, a Jacksonville, Fl...",
                    "num_datapoints": "2.1K+",
                    "num_sentiments": "4.5K+"
                },
                {
                    "brand_name": "Cafe Veloce",
                    "topic_sent":
                        {
                            "price": 0.73,
                            "crowded": 0.46,
                            "public_transportation": 0.71,
                            "design_layout": 0.94,
                            "wifi": 0.54,
                            "noise": 0.8,
                            "atmosphere": 0.84,
                            "dining": 0.69
                        },
                    "summary": "Caffe Veloce is a coffee shop chain that can be found across Japan, offering price friendly items.",
                    "num_datapoints": "4.4K+",
                    "num_sentiments": "11.1K+"
                },
                {
                    "brand_name": "Costco",
                    "topic_sent":
                        {
                            "crowded": 0.41,
                            "price": 0.75,
                            "parking": 0.32,
                            "dining": 0.74,
                            "staff": 0.4,
                            "wait_time": 0.26,
                            "accessibility": 0.81,
                            "public_transportation": 0.58
                        },
                    "summary": "Costco Wholesale Corporation is an American multinational corporation which operates a chain of membership-only big-box retail stores. As of 2020, Costco was the fifth largest retailer in the world, a...",
                    "num_datapoints": "395.0+",
                    "num_sentiments": "865.0+"
                },
                {
                    "brand_name": "Domino's",
                    "topic_sent":
                        {
                            "price": 0.54,
                            "wait_time": 0.52,
                            "staff": 0.56,
                            "service_efficiency": 0.49,
                            "parking": 0.48,
                            "accessibility": 0.92,
                            "dining": 0.55,
                            "cleanliness": 0.77
                        },
                    "summary": "Domino's Pizza, Inc, doing business as Domino's, is an American multinational pizza restaurant chain founded in 1960 and led by CEO Richard Allison. The corporation is Delaware domiciled and headquart...",
                    "num_datapoints": "1.5K+",
                    "num_sentiments": "3.2K+"
                },
                {
                    "brand_name": "Don Quijote",
                    "topic_sent":
                        {
                            "price": 0.67,
                            "dining": 0.71,
                            "parking": 0.5,
                            "accessibility": 0.9,
                            "public_transportation": 0.68,
                            "crowded": 0.3,
                            "staff": 0.42,
                            "opening_hours": 0.62
                        },
                    "summary": "Don Quijote is the largest discount store chain in Japan. Don Quijote sells daily necessities to interesting products like souvenirs and cosplay supplies. It is known for a wide range of products and ...",
                    "num_datapoints": "11.8K+",
                    "num_sentiments": "22.3K+"
                },
                {
                    "brand_name": "Doutor",
                    "topic_sent":
                        {
                            "public_transportation": 0.68,
                            "crowded": 0.5,
                            "price": 0.6,
                            "staff": 0.64,
                            "atmosphere": 0.75,
                            "cleanliness": 0.65,
                            "noise": 0.68,
                            "dining": 0.65
                        },
                    "summary": "Doutor Coffee is a Japanese retail company that specializes in coffee roasting and coffee shop franchising. It was founded by Toriba Hiromichi.",
                    "num_datapoints": "5.0K+",
                    "num_sentiments": "11.2K+"
                },
                {
                    "brand_name": "Freshness Burger",
                    "topic_sent":
                        {
                            "price": 0.43,
                            "dining": 0.7,
                            "staff": 0.63,
                            "public_transportation": 0.71,
                            "atmosphere": 0.77,
                            "service_efficiency": 0.62,
                            "crowded": 0.53,
                            "cleanliness": 0.66
                        },
                    "summary": "Freshness Burger, often referred to simply as Freshness, is a fast food restaurant from Japan. Founded in 1992, it sells hamburgers, sandwiches, salads, and coffee drinks.",
                    "num_datapoints": "1.7K+",
                    "num_sentiments": "2.9K+"
                },
                {
                    "brand_name": "Inageya",
                    "topic_sent":
                        {
                            "price": 0.58,
                            "dining": 0.66,
                            "parking": 0.62,
                            "accessibility": 0.92,
                            "staff": 0.53,
                            "public_transportation": 0.69,
                            "cleanliness": 0.82,
                            "crowded": 0.41
                        },
                    "summary": "Inageya Co., Ltd. engages in the retail business and operates through the following segments: Supermarket, Drugstore, and Retail Support. The company was founded by Namizo Saruwatari in 1900 and is he...",
                    "num_datapoints": "2.3K+",
                    "num_sentiments": "3.9K+"
                },
                {
                    "brand_name": "Ito-yokado",
                    "topic_sent":
                        {
                            "dining": 0.66,
                            "parking": 0.63,
                            "accessibility": 0.95,
                            "public_transportation": 0.73,
                            "price": 0.56,
                            "crowded": 0.5,
                            "opening_hours": 0.67,
                            "staff": 0.49
                        },
                    "summary": "Ito-Yokado is a Japanese general merchandise and department store, originally founded in 1920. In 2005, it was reorganized, as part of a corporate restructuring, as a subsidiary of the Seven & I Holdi...",
                    "num_datapoints": "4.7K+",
                    "num_sentiments": "9.0K+"
                },
                {
                    "brand_name": "Kfc",
                    "topic_sent":
                        {
                            "dining": 0.64,
                            "public_transportation": 0.68,
                            "crowded": 0.45,
                            "service_efficiency": 0.6,
                            "staff": 0.62,
                            "cleanliness": 0.66,
                            "wait_time": 0.38,
                            "price": 0.52
                        },
                    "summary": "KFC is an American fast food restaurant chain headquartered in Louisville, Kentucky that specializes in fried chicken. It is the world's second-largest restaurant chain after McDonald's, with 22,621 l...",
                    "num_datapoints": "1.7K+",
                    "num_sentiments": "3.1K+"
                },
                {
                    "brand_name": "Kitchen Origin",
                    "topic_sent":
                        {
                            "dining": 0.61,
                            "price": 0.63,
                            "staff": 0.52,
                            "accessibility": 0.92,
                            "opening_hours": 0.6,
                            "service_efficiency": 0.42,
                            "public_transportation": 0.68,
                            "crowded": 0.38
                        },
                    "summary": "Kitchen Origin, owned by AEON, is a take-out only bento shop. Locations are open 24 hours a day.",
                    "num_datapoints": "899.0+",
                    "num_sentiments": "1.6K+"
                },
                {
                    "brand_name": "Life Supermarket",
                    "topic_sent":
                        {
                            "dining": 0.68,
                            "price": 0.67,
                            "parking": 0.57,
                            "accessibility": 0.92,
                            "public_transportation": 0.71,
                            "staff": 0.51,
                            "crowded": 0.4,
                            "cleanliness": 0.83
                        },
                    "summary": "Life Corp. owns and operates supermarkets that retail food products and clothing. It operates through the retail and others segments. The Retail segment sells food, daily commodities and cloth. The Ot...",
                    "num_datapoints": "6.0K+",
                    "num_sentiments": "10.6K+"
                },
                {
                    "brand_name": "Lotteria",
                    "topic_sent":
                        {
                            "public_transportation": 0.66,
                            "dining": 0.72,
                            "price": 0.34,
                            "crowded": 0.51,
                            "staff": 0.58,
                            "accessibility": 0.87,
                            "service_efficiency": 0.7,
                            "cleanliness": 0.58
                        },
                    "summary": "LOTTERIA Co., Ltd. is a Japanese company that operates a chain of fast food restaurants in East Asia, having opened its first restaurant in Tokyo in September 1972.",
                    "num_datapoints": "1.1K+",
                    "num_sentiments": "2.2K+"
                },
                {
                    "brand_name": "Marriott",
                    "topic_sent":
                        {
                            "staff": 0.89,
                            "dining": 0.78,
                            "public_transportation": 0.84,
                            "service_efficiency": 0.88,
                            "cleanliness": 0.89,
                            "accessibility": 0.96,
                            "price": 0.3,
                            "bathroom": 0.39
                        },
                    "summary": "Marriott Hotels & Resorts is Marriott International's brand of full-service hotels and resorts based in Bethesda, Maryland. As of June 30, 2020, there were 582 hotels and resorts with 205,053 rooms op...",
                    "num_datapoints": "488.0+",
                    "num_sentiments": "976.0+"
                },
                {
                    "brand_name": "Maruetsu",
                    "topic_sent":
                        {
                            "price": 0.58,
                            "dining": 0.62,
                            "accessibility": 0.92,
                            "public_transportation": 0.75,
                            "opening_hours": 0.63,
                            "staff": 0.53,
                            "parking": 0.52,
                            "crowded": 0.44
                        },
                    "summary": "The Maruetsu,Inc. operates chain supermarket. The Company sells groceries, perishables, clothing, sundry goods, and other products. The Maruetsu operates businesses in Japan.",
                    "num_datapoints": "4.6K+",
                    "num_sentiments": "9.1K+"
                },
                {
                    "brand_name": "Matsuya",
                    "topic_sent":
                        {
                            "dining": 0.65,
                            "price": 0.74,
                            "public_transportation": 0.68,
                            "cleanliness": 0.54,
                            "staff": 0.53,
                            "service_efficiency": 0.52,
                            "crowded": 0.44,
                            "wait_time": 0.48
                        },
                    "summary": "Matsuya Foods Co. is a chain of restaurants, including Matsuya, which sells gy\u016bdon, Japanese curry, and teishoku. Matsuya was established in Japan in 1966, founded by Toshio Kawarabuki. As of 2018, Ma...",
                    "num_datapoints": "6.1K+",
                    "num_sentiments": "11.2K+"
                },
                {
                    "brand_name": "Mcdonald's",
                    "topic_sent":
                        {
                            "crowded": 0.42,
                            "public_transportation": 0.69,
                            "staff": 0.54,
                            "service_efficiency": 0.54,
                            "dining": 0.62,
                            "opening_hours": 0.41,
                            "cleanliness": 0.59,
                            "accessibility": 0.88
                        },
                    "summary": "McDonald's is an American multinational fast food corporation, founded in 1940 as a restaurant operated by Richard and Maurice McDonald, in San Bernardino, California, United States.",
                    "num_datapoints": "16.9K+",
                    "num_sentiments": "39.9K+"
                },
                {
                    "brand_name": "Mos Burger",
                    "topic_sent":
                        {
                            "public_transportation": 0.69,
                            "staff": 0.71,
                            "dining": 0.68,
                            "crowded": 0.46,
                            "service_efficiency": 0.65,
                            "cleanliness": 0.79,
                            "price": 0.4,
                            "wait_time": 0.48
                        },
                    "summary": "MOS Food Services, Inc., doing business as MOS Burger, is an international fast-food restaurant chain from Japan. Its headquarters are in the ThinkPark Tower in \u014csaki, Shinagawa, Tokyo. At one time it...",
                    "num_datapoints": "4.7K+",
                    "num_sentiments": "9.3K+"
                },
                {
                    "brand_name": "Ok Supermarkets",
                    "topic_sent":
                        {
                            "price": 0.75,
                            "parking": 0.45,
                            "dining": 0.7,
                            "crowded": 0.4,
                            "accessibility": 0.81,
                            "staff": 0.43,
                            "public_transportation": 0.63,
                            "wait_time": 0.43
                        },
                    "summary": "OK CORPORATION is located in OTA-KU, TOKYO, Japan and is part of the Department Stores Industry. There are 126 companies in the OK CORPORATION corporate family.",
                    "num_datapoints": "8.9K+",
                    "num_sentiments": "17.0K+"
                },
                {
                    "brand_name": "Pronto",
                    "topic_sent":
                        {
                            "public_transportation": 0.74,
                            "dining": 0.67,
                            "crowded": 0.49,
                            "price": 0.63,
                            "staff": 0.68,
                            "atmosphere": 0.77,
                            "accessibility": 0.91,
                            "service_efficiency": 0.64
                        },
                    "summary": "Pronto Corporation is the operator of some 300 establishments mainly consisting of the Pronto restaurant chain, which blends the concepts of cafe and bar; and Izutsu Maisen, the producer and seller of...",
                    "num_datapoints": "1.7K+",
                    "num_sentiments": "2.7K+"
                },
                {
                    "brand_name": "Seijo Ishii",
                    "topic_sent":
                        {
                            "dining": 0.66,
                            "price": 0.39,
                            "public_transportation": 0.71,
                            "accessibility": 0.88,
                            "staff": 0.59,
                            "crowded": 0.37,
                            "opening_hours": 0.55,
                            "parking": 0.52
                        },
                    "summary": "Seijo Ishii Co. Ltd. operates a food supermarket chain. The Company retails meat, noodles, vegetables, fruits, wines, beverages, and other processed food products.",
                    "num_datapoints": "2.0K+",
                    "num_sentiments": "3.2K+"
                },
                {
                    "brand_name": "Seiyu Supermarket",
                    "topic_sent":
                        {
                            "price": 0.76,
                            "dining": 0.64,
                            "opening_hours": 0.63,
                            "accessibility": 0.92,
                            "public_transportation": 0.74,
                            "parking": 0.5,
                            "staff": 0.41,
                            "crowded": 0.37
                        },
                    "summary": "Seiyu GK, or Seiyu Group, is a Japanese group of supermarkets, shopping centers and department stores, headquartered in Akabane, Kita, Tokyo.",
                    "num_datapoints": "7.1K+",
                    "num_sentiments": "13.4K+"
                },
                {
                    "brand_name": "Shake Shack",
                    "topic_sent":
                        {
                            "price": 0.27,
                            "dining": 0.66,
                            "crowded": 0.42,
                            "atmosphere": 0.84,
                            "staff": 0.75,
                            "wait_time": 0.45,
                            "service_efficiency": 0.59,
                            "public_transportation": 0.66
                        },
                    "summary": "Shake Shack is an American fast casual restaurant chain based in New York City. It started out as a hot dog cart inside Madison Square Park in 2001, and its popularity steadily grew.",
                    "num_datapoints": "1.5K+",
                    "num_sentiments": "2.3K+"
                },
                {
                    "brand_name": "Sheraton",
                    "topic_sent":
                        {
                            "staff": 0.8,
                            "public_transportation": 0.68,
                            "dining": 0.56,
                            "service_efficiency": 0.64,
                            "cleanliness": 0.8,
                            "accessibility": 0.79,
                            "price": 0.34,
                            "design_layout": 0.95
                        },
                    "summary": "Sheraton Hotels and Resorts is an international semi-luxury hotel chain owned by Marriott International.",
                    "num_datapoints": "692.0+",
                    "num_sentiments": "1.6K+"
                },
                {
                    "brand_name": "Starbucks",
                    "topic_sent":
                        {
                            "crowded": 0.42,
                            "public_transportation": 0.61,
                            "staff": 0.74,
                            "atmosphere": 0.75,
                            "service_efficiency": 0.58,
                            "design_layout": 0.86,
                            "noise": 0.66,
                            "accessibility": 0.83
                        },
                    "summary": "Starbucks Corporation is an American multinational chain of coffeehouses and roastery reserves headquartered in Seattle, Washington. It is the world's largest coffeehouse chain. As of November 2021, t...",
                    "num_datapoints": "11.4K+",
                    "num_sentiments": "29.6K+"
                },
                {
                    "brand_name": "Subway",
                    "topic_sent":
                        {
                            "public_transportation": 0.69,
                            "staff": 0.63,
                            "dining": 0.63,
                            "service_efficiency": 0.49,
                            "crowded": 0.4,
                            "cleanliness": 0.68,
                            "price": 0.66,
                            "accessibility": 0.91
                        },
                    "summary": "Subway is an American multi-national fast food restaurant franchise that primarily sells submarine sandwiches, wraps, salads and beverages. Subway was founded by 17 year old Fred DeLuca and financed b...",
                    "num_datapoints": "738.0+",
                    "num_sentiments": "1.7K+"
                },
                {
                    "brand_name": "Sukiya",
                    "topic_sent":
                        {
                            "dining": 0.68,
                            "staff": 0.49,
                            "price": 0.81,
                            "service_efficiency": 0.44,
                            "parking": 0.57,
                            "public_transportation": 0.62,
                            "cleanliness": 0.44,
                            "crowded": 0.42
                        },
                    "summary": "Sukiya is the largest chain of gy\u016bdon restaurant. Sukiya's owner, Zensho Holdings, is listed on the Tokyo Stock Exchange and had sales of \u00a5511 billion in 2016. Its slogan is \"save time and money\". Acc...",
                    "num_datapoints": "3.4K+",
                    "num_sentiments": "6.6K+"
                },
                {
                    "brand_name": "Sushiro",
                    "topic_sent":
                        {
                            "crowded": 0.5,
                            "price": 0.71,
                            "wait_time": 0.47,
                            "dining": 0.61,
                            "parking": 0.58,
                            "service_efficiency": 0.48,
                            "staff": 0.61,
                            "public_transportation": 0.69
                        },
                    "summary": "Akindo Sushiro is a Japanese multinational conveyor belt sushi specialty store. It is headquartered in Suita, Osaka. Sushiro is currently the largest conveyor belt sushi company in turnover. The total...",
                    "num_datapoints": "7.6K+",
                    "num_sentiments": "15.1K+"
                },
                {
                    "brand_name": "Tully's",
                    "topic_sent":
                        {
                            "public_transportation": 0.7,
                            "crowded": 0.49,
                            "staff": 0.73,
                            "wifi": 0.5,
                            "atmosphere": 0.81,
                            "noise": 0.76,
                            "accessibility": 0.89,
                            "dining": 0.62
                        },
                    "summary": "Tully's Coffee Co Inc was founded in 1998. The Company's line of business includes the manufacturing of coffee concentrates and extracts in powered, liquid, and frozen form.",
                    "num_datapoints": "3.5K+",
                    "num_sentiments": "8.5K+"
                },
                {
                    "brand_name": "Uniqlo",
                    "topic_sent":
                        {
                            "price": 0.72,
                            "public_transportation": 0.71,
                            "staff": 0.6,
                            "crowded": 0.51,
                            "accessibility": 0.86,
                            "parking": 0.66,
                            "design_layout": 0.77,
                            "service_efficiency": 0.6
                        },
                    "summary": "Uniqlo Co., Ltd. is a Japanese casual wear designer, manufacturer and retailer. The company is a wholly owned subsidiary of Fast Retailing Co., Ltd.",
                    "num_datapoints": "4.5K+",
                    "num_sentiments": "8.9K+"
                },
                {
                    "brand_name": "Wendy's",
                    "topic_sent":
                        {
                            "dining": 0.75,
                            "price": 0.45,
                            "public_transportation": 0.67,
                            "staff": 0.61,
                            "crowded": 0.52,
                            "service_efficiency": 0.7,
                            "accessibility": 0.95,
                            "cleanliness": 0.61
                        },
                    "summary": "Wendy's is an American international fast food restaurant chain founded by Dave Thomas on November 15, 1969, in Columbus, Ohio. The company moved its headquarters to Dublin, Ohio, on January 29, 2006.",
                    "num_datapoints": "1.1K+",
                    "num_sentiments": "2.0K+"
                },
                {
                    "brand_name": "Yoshinoya",
                    "topic_sent":
                        {
                            "dining": 0.65,
                            "price": 0.77,
                            "staff": 0.58,
                            "service_efficiency": 0.56,
                            "public_transportation": 0.68,
                            "crowded": 0.46,
                            "cleanliness": 0.66,
                            "parking": 0.66
                        },
                    "summary": "Yoshinoya is a Japanese multinational fast food chain, and the second-largest chain of gy\u016bdon restaurants. The chain was established in Japan in 1899. Its motto is \"Tasty, low-priced, and quick\".",
                    "num_datapoints": "5.0K+",
                    "num_sentiments": "8.2K+"
                }]
        }],
    brandToAnalyze: null,
    cityToShow: null
};

const reducer = (state, action) => {
    return {...state, ...action}
};

export const AppContextProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AppContext.Provider value={{state, dispatch}}>
            {props.children}
        </AppContext.Provider>
    )
};
