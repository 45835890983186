import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    maintainAspectRatio: false,

    scales: {
        x: {
            display: true,
            grid: {
                offset: true
            },
            stacked: true,
            ticks: {
                color: "white",
                font: {
                    size: 13,
                },
                autoSkip: false,
            //     maxRotation: 90,
            //     minRotation: 90
            }
        },
        y: {
            display: false

        }
    },
    barThickness: 20,
    label: true,
    plugins: {
        legend: {
            display: false

        },
        title: {
            display: false,
            text: 'Consumer Pe',
        },
    },
};

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
//
// export const data = {
//     labels,
//     datasets: [
//         {
//             label: 'Dataset 1',
//             data: labels.map(() => [0,1,2]),
//             backgroundColor: 'rgba(255, 99, 132, 0.5)',
//         },
//         {
//             label: 'Dataset 2',
//             data: labels.map(() => [0,1,2]),
//             backgroundColor: 'rgba(53, 162, 235, 0.5)',
//         },
//     ],
// };

export default function BarChartJs({ perceptions }) {
    const labels = Object.keys(perceptions)
    const perceptionValues = Object.values(perceptions)

    // function determineColorForBar(perceptionTitle) {
    //     return '#88324j'
    // }


    // const datasets = perceptions.map(perception => {
    // // generates multiple bars per label
    //     return {
    //         data: [Object.values(perception),],
    //         borderWidth: 2,
    //         borderRadius: 100,
    //         borderSkipped: false,
    //         backgroundColor: determineColorForBar(Object.keys(perception))
    //     }
    // })

    const data = {
        labels,
        datasets: [
            {
                label: '',
                showLabel: true,
                data: perceptionValues,
                borderWidth: 0,
                borderRadius: 100,
                borderSkipped: false,
                backgroundColor: 'rgb(81,214,243)',
                hoverBorderColor: 'rgb(222,154,32)',
                hoverBorderWidth: 1
            },
        ],
    };

    return <Bar options={options} data={data} />;
}
