// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function InstagramSvgrepoComSvgIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"currentColor"}
      version={"1.1"}
      viewBox={"0 0 512 512"}
      xmlSpace={"preserve"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        display={"inline"}
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M66.084.5h379.819C481.982.5 511.5 30.005 511.5 66.084v379.819c0 36.079-29.518 65.597-65.597 65.597H66.084C30.005 511.5.5 481.982.5 445.903V66.084C.5 30.005 30.005.5 66.084.5zm306.65 56.764c-12.65 0-23.005 10.355-23.005 23.005v55.067c0 12.65 10.354 23.005 23.005 23.005h57.762c12.65 0 23.005-10.354 23.005-23.005V80.269c0-12.65-10.354-23.005-23.005-23.005h-57.762zm81.004 159.326h-44.975a149.514 149.514 0 016.55 43.852c0 84.996-71.111 153.898-158.839 153.898-87.716 0-158.827-68.902-158.827-153.898a149.49 149.49 0 016.55-43.852H57.276v215.853c0 11.178 9.132 20.322 20.311 20.322h355.841c11.166 0 20.311-9.145 20.311-20.322V216.59h-.001zm-197.263-61.143c-56.677 0-102.625 44.525-102.625 99.443s45.948 99.443 102.625 99.443c56.688 0 102.636-44.525 102.636-99.443s-45.948-99.443-102.636-99.443z"
        }
      ></path>
    </svg>
  );
}

export default InstagramSvgrepoComSvgIcon;
/* prettier-ignore-end */
