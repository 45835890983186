// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicCityLocCountCircleChart } from "./plasmic/basil_site_2_0/PlasmicCityLocCountCircleChart";

function CityLocCountCircleChart_(props, ref) {
  // Use PlasmicCityLocCountCircleChart to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicCityLocCountCircleChart are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all CityLocCountCircleChartProps here, but feel free
  // to do whatever works for you.
  return <PlasmicCityLocCountCircleChart root={{ ref }} {...props} />;
}

const CityLocCountCircleChart = React.forwardRef(CityLocCountCircleChart_);

export default CityLocCountCircleChart;
