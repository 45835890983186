import React, {useEffect, useState} from "react";
import ReactApexChart from "react-apexcharts";
import {getCategories, getValuesForCategories} from "../helperComponents/utils";

export const RadialChart = ({perceptions}) => {


    console.log('perceptions - radial')
    console.log(Object.values(perceptions))
    const [state, setState] = useState({
        series: Object.values(perceptions).map(perception => {
            return perception *= 100
        }),
        options: {
            track: {
                background: '#2f3237',
                show: true,
            },
            chart: {
                height: 100,
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: "40%"
                    },
                    track: {
                        show: true,
                        background: '#222830',
                        strokeWidth: '80%',
                        opacity: '40%'
                    },
                    dataLabels: {
                        name: {
                            fontSize: '1.2em',
                            offsetY: -10,
                        },
                        value: {
                            fontSize: '2em',
                            color: '#ffffff'
                        },
                        total: {
                            show: true,
                            label: 'Total',
                            color: '#c2c2c2',
                            formatter: function (w) {
                                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                console.log(w.config.series.reduce((a, b) => a + b) / w.config.series.length)
                                return (w.config.series.reduce((a, b) => a + b) / w.config.series.length).toFixed(2)
                            }
                        }
                    }
                }
            },
            stroke: {
                lineCap: "round",
            },
            labels: Object.keys(perceptions),
        },
    });
    useEffect(() => {
        setState({
            series: Object.values(perceptions).map(perception => {
                return perception *= 100
            }),
            options: {
                track: {
                    background: '#2f3237',
                    show: true,
                },
                chart: {
                    height: 100,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: "40%"
                        },
                        track: {
                            show: true,
                            background: '#222830',
                            strokeWidth: '80%',
                            opacity: '40%'
                        },
                        dataLabels: {
                            name: {
                                fontSize: '1.2em',
                                offsetY: -10,
                            },
                            value: {
                                fontSize: '2em',
                                color: '#ffffff'
                            },
                            total: {
                                show: true,
                                label: 'Total',
                                color: '#c2c2c2',
                                formatter: function (w) {
                                    // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                    console.log(w.config.series.reduce((a, b) => a + b) / w.config.series.length)
                                    return (w.config.series.reduce((a, b) => a + b) / w.config.series.length).toFixed(2)
                                }
                            }
                        }
                    }
                },
                stroke: {
                    lineCap: "round",
                },
                labels: Object.keys(perceptions),
            },
        });
    }, [perceptions])
    return (
        <div id="chart">
            <ReactApexChart options={state.options} series={state.series} type="radialBar" height={350}/>
        </div>
    )
}

