// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicForm } from "./plasmic/basil_site_2_0/PlasmicForm";
import { useContext, useState, useEffect } from "react";
import { AppContext } from "../helperComponents/AppContext";

const Airtable = require('airtable');

function Form_(props, ref) {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [cityResidence, setCityResidence] = useState('')
    const [industry, setIndustry] = useState('')
    const [email, setEmail] = useState('')
    const [subscribed, setSubscribed] = useState(false)

    const [ipData, setIpData] = useState('')

    const getIp = async () => {
        // Connect ipapi.co with fetch()
        const response = await fetch('https://ipapi.co/json/')
        const data = await response.json()
        // Set the IP address to the constant `ip`
        setIpData(data)
    }
    // Run `getIP` function above just once when the page is rendered
    useEffect(() => {
        getIp()
    }, [])

    Airtable.configure({
        endpointUrl: 'https://api.airtable.com',
        apiKey: process.env.REACT_APP_AIRTABLE_API_KEY
    });
    var base = Airtable.base('appFiG3k1MiZwfmw4');


    async function sendDataToAirTable() {
        await base('terminal_contact').create([
            {
                "fields": {
                    'firstName': firstName,
                    'lastName': lastName,
                    'cityResidence': cityResidence,
                    'industry': industry,
                    'email': email,
                    'time': new Date(Date.now()).toDateString(),
                    'ip': JSON.stringify(ipData)
                }
            },

        ], function (err, records) {
            if (err) {
                console.error(err);
                return;
            }
            setFirstName('')
            setLastName('')
            setCityResidence('')
            setIndustry('')
            setEmail('')
            setSubscribed(true)
            records.forEach(function (record) {
                console.log(record.getId());
            });
        });

    }

    return <PlasmicForm root={{ ref }} {...props}
        fname={{
            autoFocus: false,
            value: firstName,

            onChange: (e) => {
                setFirstName(e.target.value);
            },
        }}

        lname={{
            autoFocus: false,
            value: lastName,

            onChange: (e) => {
                setLastName(e.target.value);
            },
        }}

        cityresidence={{
            autoFocus: false,
            value: cityResidence,

            onChange: (e) => {
                setCityResidence(e.target.value);
            },
        }}

        industry={{
            autoFocus: false,
            value: industry,

            onChange: (e) => {
                setIndustry(e.target.value);
            },
        }}

        wemail={{
            autoFocus: false,
            value: email,

            onChange: (e) => {
                setEmail(e.target.value);
            },
        }}


        formEnterButton={{
            onClick: () => {
                sendDataToAirTable()
            },
            subscribed: subscribed
        }
        } />;
}

const Form = React.forwardRef(Form_);

export default Form;
