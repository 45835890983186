// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function BackSvgrepoComSvgIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 24 24"}
      fill={"none"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M6.75 5.25v13.5h1.5V5.25h-1.5zM9.148 12L18 17.901V6.1L9.148 12zM16.5 8.901V15.1L11.852 12 16.5 8.901z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default BackSvgrepoComSvgIcon;
/* prettier-ignore-end */
