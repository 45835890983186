import React, { useContext, useEffect, useState } from 'react';
import LoadingGIF from "./../resources/loading.gif";



function LoadingScreen() {
    return (
        <div style={{
            backgroundColor: "#161620",
            width: "100%",
            height: "100vh",
            backgroundSize: "100%",
            margin: 0
        }}>
            <div style={{
                backgroundColor: "#161620",
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                // transform: "-webkit-translate(-50%, -50%)",
                // transform: "-moz-translate(-50%, -50%)",
                // transform: "-ms-translate(-50%, -50%)",
                // color: "white",
            }}>
                <div>
                    <img src={LoadingGIF} alt="Loading..." width={380} height={380} />
                </div>
            </div>
        </div>
    )
}

export default LoadingScreen