import React, {useEffect} from 'react';
import {useLocation, useNavigate} from "react-router";

const UseProtectPage = (shouldRedirect) => {
    // let history = useHistory()
    // const user = firebase.auth().currentUser;
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (shouldRedirect) navigate('/global-rankings')
    })
};

export default UseProtectPage;
