// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function RealEstateSvgrepoComSvgIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"currentColor"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M19 2l3 7h-5l-5-5-5 5H2l3-7zm-3 9h6v11h-8v-6h-4v6H2V11h6l4-4zm-8 7H4v2h4zm0-4H4v2h4zm5-3a1 1 0 10-1 1 1 1 0 001-1zm7 7h-4v2h4zm0-4h-4v2h4z"
        }
      ></path>
    </svg>
  );
}

export default RealEstateSvgrepoComSvgIcon;
/* prettier-ignore-end */
